.top-toggle-full-screen-section {
  z-index: 1002;
  position: absolute;
  top: 0;
  right: 0;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  cursor: pointer;
  padding-left: 15px;
  padding-right: 15px;
  border-bottom-left-radius: 5px;

  svg {
    width: 20px;
    height: 20px;
  }

  .chat-control-section {
    margin-right: 15px;
    svg {
      width: 25px;
      height: 25px;
    }
  }
}
.video-page-top-nav-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.75);
  border-top: 1px solid #333;
  z-index: 1002;

  .app-logo-section {
    img {
      max-height: 50px;
    }
  }

  .video-session-info-container {
    position: absolute;
    left: 0;
    top: 0;
    padding: 15px;
    min-height: 80px;
    max-width: 350px;
    display: flex;
    align-items: center;
    

    p {
      font-size: 18px;
      color: #f1f1f1;
      font-weight: 600;
    }
  }

  .video-tools-container {
    text-align: center;

    button {
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }

    button.mic-button {
      background-color: #3c9be2;
      border-color: #3c9be2;
    }

    button.btn-outline-default {
      background-color: #efefef;
      color: #3c9be2;
    }
  }

  .screen-share-section {
    position: absolute;
    right: 0px;
    top: 0px;

  }

  .mute-section {
    position: absolute;
    right: 145px;
    top: 0px;

    .mute-now-section {
      min-width: 125px;
      background-color: #000;
      padding: 15px;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      border-left: 1px solid #222;
      cursor: pointer;

      p {
        margin: 2px 0 0;
        color: #3c9be2;
        font-weight: 600;
      }

      i {
        font-size: 28px;
        color: #3c9be2;
      }
    }
  }

  .lock-section {
    position: absolute;
    right: 270px;
    top: 0px;

    .lock-now-section {
      min-width: 100px;
      background-color: #000;
      padding: 15px;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      border-left: 1px solid #222;
      cursor: pointer;

      p {
        margin: 2px 0 0;
        color: #ee5335;
        font-weight: 600;
      }

      i {
        font-size: 28px;
        color: #ee5335;
      }
    }
  }

  .screen-share-section {
    position: absolute;
    right: 0px;
    top: 0px;

    .share-now-section {
      background-color: #000;
      padding: 15px;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      border-left: 1px solid #222;
      cursor: pointer;

      svg {
        width: 28px;
        height: 28px;
      }

      p {
        margin: 2px 0 0;
        color: #50b37e;
        font-weight: 600;
      }
    }

    .stop-now-section {
      background-color: #000;
      padding: 15px;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      border-left: 1px solid #222;
      cursor: pointer;

      i {
        font-size: 28px;
        color: #ee5335;
      }

      p {
        margin: 2px 0 0;
        color: #ee5335;
        font-weight: 600;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .video-page-main-container {
    .top-toggle-full-screen-section {
      right: 0;
      position: fixed;
    }
  }
  .video-page-top-nav-container {
    .video-session-info-container {
      padding: 10px;
      .app-logo-section {
        display: flex;
        align-items: center;
        span {
          color: #fff;
          font-size: 20px;
          margin-right: 10px;
          display: flex;
          align-items: center;
        }
        img {
          max-height: 30px;
        }
      }
    }
    .video-tools-container {
      text-align: right;
    }
    .lock-section {
      display: none;
    }
    .mute-section {
      display: none;
    }
  }

  .extra-options-section-xs {
    position: fixed;
    bottom: 75px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.75);
    width: 185px;
    z-index: 1005;
    height: 170px;

    .lock-section {
      position: relative;
      left: 0;
      right: 0;
      display: block;
      .lock-now-section {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        width: 100%;
        height: 55px;
        min-width: unset;
        background-color: rgba(0, 0, 0, 0.75);
        border-bottom: 1px solid #333;

        i {
          color: #ee5335;
          font-size: 28px;
          padding-right: 10px;
        }

        p {
          font-size: 16px;
          color: #ee5335;
          display: block;
        }
      }
    }
    .mute-section {
      position: relative;
      left: 0;
      right: 0;
      display: block;
      .mute-now-section {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        width: 100%;
        height: 55px;
        min-width: unset;
        background-color: rgba(0, 0, 0, 0.75);
        border-bottom: 1px solid #333;

        i {
          color: #3c9be2;
          font-size: 28px;
          padding-right: 10px;
        }

        p {
          font-size: 16px;
          color: #3c9be2;
          display: block;
        }
      }
      p {
        font-size: 10px;
      }
    }

    .screen-share-section {
      position: relative;
      left: 0;
      right: 0;
      display: block;

      .share-now-section {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        height: 60px;

        p {
          margin-left: 10px;
        }
      }
    }
  }
}