.about-us-details-container {
    .about-us-main-content {
        .demo-form {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .demo-left-side-section {
                flex: 0 0 48%;
                text-align: center;
                img {
                    border-top-left-radius: 30px;
                    border-bottom-left-radius: 30px;
                    max-height: 500px;
                }
            }

            .demo-right-side-section {
                flex: 0 0 48%;
                margin-right: 30px;
                .demo-form-container {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .demo-input {
                        flex: 0 0 48%
                    }

                    .demo-textarea-input {
                        width: 100%;
                    }

                    .demo-submit-button {
                        width: 100%;
                        height: 50px;
                        background-color: #3c9be2;
                        border: none;
                        border-radius: 5px;
                        color: #fff;
                        font-size: 16px;
                        font-weight: 600;
                        cursor: pointer;
                        outline: none;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .about-us-details-container {
        .about-us-main-content {
            .demo-form {
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                .demo-left-side-section {
                    flex: 0 0 100%;

                    img {
                        border-top-right-radius: 30px;
                    }
                }

                .demo-right-side-section {
                    flex: 0 0 100%;
                    padding: 15px;
                    box-sizing: border-box;

                    .demo-form-container {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        flex-wrap: wrap;
                        .demo-input {
                            flex: 0 0 100%
                        }
                    }
                }
            }
        }
    }
}