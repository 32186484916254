
/**
 * Canvas
 */

 #whiteboard {
  width: 100%;
  height: 100vh;
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1000;
 }

 .whiteboard {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.color {
  display: inline-block;
  height: 30px;
  width: 30px;
  position: relative;
  cursor: pointer;
}

.color.active {
  &::before {
    content: '\e8d9';
    color: #fff;
    position: absolute;
    left: 5px;
    top: -2px;
    font-family: 'unicons';
    font-size: 20px;
  }
}

.color.black { background-color: black; }
.color.red { background-color: red; }
.color.green { background-color: green; }
.color.blue { background-color: blue; }
.color.yellow { background-color: yellow; }

.display-top, .images {
  position: relative;
  z-index: 9999;
}

.top-controls-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 999;
  width: 100%;
  padding: 10px;

  .colors-container {  
    .color {
      border-radius: 40px;
      margin-right: 10px;
  
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .upload-file-section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .choose-file-section {
      margin-left: 15px;
  
      label {
          background-color: #3e416c;
          padding: 9px 15px;
          margin-top: 0;
          border-radius: 5px;
          cursor: pointer;
          margin-bottom: 0;
          color: #fff;
          font-size: 14px;
          font-weight: 600;

          i {
            color: #fff;
            font-size: 18px;
          }
      }
      input {
        display: none;
      }
    }

    .buttons-container {
      button {
        margin-left: 10px;
        padding: 9px 15px;
        margin-top: 0;
        border-radius: 5px;
        cursor: pointer;
        margin-bottom: 0;
        color: #fff;
        font-size: 14px;
        border: none;
        font-weight: 600;
      }

      #delete {
        background-color: #ee5335;
      }

      #reset {
        background-color: #3c9be2;
      }

      #download {
        background-color: #50b37e;
      }
    }
  }
}

.uploaded-images-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: 70px;
  left: 0px;
  z-index: 999;
  width: 100%;
  .uploaded-image {
    max-width: 100px;
    max-height: 100px;
    border: 2px solid #3e416c;
    border-radius: 5px;
    margin-left: 10px;
  }
}

@media screen and (max-width: 767px) {
  #whiteboard {
    position: fixed;
  }
  .top-controls-container {
    .upload-file-section {
      flex-wrap: wrap;

      .buttons-container {
        button {
          margin-top: 10px;
        }
      }
    }
  }

  .uploaded-images-container {
    top: 150px;
  }
}