.app-dashboard-details-main-container {
    .add-quiz-form-container {
        padding: 15px;
        .aq-quiz-top-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .aq-left-side {
                flex: 0 0 70%;

                .aq-q-meta-info {
                    margin: 0 0 5px;
                    font-size: 14px;
                    span {
                        color: #3c9be2;
                    }
                }
            }

            .aq-right-side {
                flex: 0 0 30%;

                div.glyph {
                    &:first-child {
                        border: 1px solid #f1f1f1;
                        border-radius: 10px;
                    }
                }
            }
        }

        .aq-quiz-bottom-container {
            .add-quiz-form-flex-container {
                padding: 15px 0;
                .add-quiz-form-flex {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;

                    div {
                        width: calc(100% * 1/4.0001 - 20px);
                        margin: 10px;

                        label {
                            font-size: 14px;
                            color: #3e416c;
                            font-weight: 600;
                        }

                        input {
                            margin-bottom: 0;
                        }

                        button {
                            height: 2.875pc;
                            margin-top: 32px;
                        }

                        select {
                            height: 2.875pc;
                            margin-bottom: 0;
                        }
                    }

                    div.srv-validation-message {
                        width: 100%;
                    }
                }
            }

            .load-previous-questions-container {
                padding: 15px 10px;

                button.load-button {
                    border-radius: 5px;
                }
            }
        }

        .quiz-submission-details-container {
            .student-details-flex-container {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 0 0 15px;
                border-top: none;
                .left-side {
                    width: 300px;

                    h4 {
                        font-size: 14px;
                        font-weight: 600;
                    }

                    p {
                        font-size: 12px;
                        font-weight: 600;
                    }

                    p.show-more-link {
                        text-decoration: underline;
                        cursor: pointer;
                        color: #3c9be2;
                    }
                }

                .right-side {
                    width: calc(100% - 315px);
                    .flex-container {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;

                        .quiz-stat-section {
                            width: 65px;
                            height: 65px;
                            border-radius: 50%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            flex-direction: column;
                            margin-right: 15px;

                            h6 {
                                font-weight: 600;
                                font-size: 16px;
                                margin: 0;
                                color: #fff;
                            }

                            p {
                                font-size: 10px;
                                font-weight: 600;
                                color: #f1f1f1;
                            }

                            &:last-child {
                                background-color: #f1f1f1;
                                
                                h6 {
                                    color: #3e416c;
                                }

                                p {
                                    color: #3e416c;
                                }
                            }
                        } 
                    }
                }
            }

            .student-quiz-data-m--container {
                padding: 15px;
                border: 1px solid #f1f1f1;
                border-top: none;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
            }

            .submission-deatils-accordion-container {
                margin: 15px;
                .extra-details--row {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 0 15px;
                    margin-bottom: 15px;
                    .answer-heading {
                        margin-right: 20px;
                        font-size: 18px;
                        font-weight: 600;
                    }

                    .student-answer-section {
                        margin-right: 20px;
                        font-size: 12px;
                        font-weight: 600;
                    }

                    .time-taken-section {
                        font-size: 12px;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}

.aq-q-container-collapsible-container {
    .question-collapse-header {
        border: 1px solid #f1f1f1;
        border-radius: 10px 10px 0 0;
        margin-bottom: 15px;

        .card-header {
            label {
                cursor: pointer;
                color: #3e416c;
            }
        }

        .question-collapse {
            .save-button-container {
                padding: 15px 30px;
                button {
                    i {
                        padding-right: 5px;
                    }
                }
            }
            .card-content {
                .card-body {

                    .form-flex-row {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;

                        .left-side {
                            width: 150px;
                            margin-right: 15px;
                            text-align: right;

                            label {
                                font-size: 14px;
                                font-weight: 600;
                                color: #3e416c;
                            }
                        }

                        .right-side {
                            width: calc(100% - 165px);
                            max-width: 600px;
                            
                            select {
                                display: inline-block;
                                width: auto;
                                height: 2.857pc;
                            }

                            .options-flex-container {
                                display: flex;
                                justify-content: flex-start;
                                flex-wrap: wrap;
                                .question-checkbox-options-container {
                                    width: calc(100% * 1 / 2.001 - 20px);
                                    margin: 0 10px;
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: center;
                                    margin-bottom: 10px;

                                    div {
                                        &:first-child {
                                            width: 20px;
                                            margin-right: 10px;

                                            input {
                                                width: 20px !important;
                                                height: 20px !important;
                                                margin-bottom: 0;
                                            }
                                        }
                                    }

                                    label {
                                        width: calc(100% - 30px);
                                        input {
                                            max-width: 200px;
                                            margin-bottom: 0px;
                                        }
                                    }
                                }
                            }

                        }
                    }

                    .form-flex-row.align-start {
                        align-items: flex-start;
                    }

                    .match-the-following-options-flex {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        margin-bottom: 10px;

                        .left-side {
                            min-width: 150px;

                            span {
                                font-size: 14px;
                                color: #414242;
                                font-weight: 600;
                            }
                        }

                        .right-side {
                            margin-left: 10px;
                            width: calc(100% - 160px);
                        }
                    }
                }
            }
        }
    }
}