.dashboard-main-container {
	padding: 15px;

	.db-student-top-flex-container {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		margin-bottom: 15px;
		flex-wrap: wrap;
		.db-s-left-side-section {
			flex: 0 0 65%;
            min-width: 700px;
            max-width: 700px;
            background-color: #fff;
            box-shadow: 0.5pt 10px 14px -0.09375in rgba(25,42,70,.13);
            height: 300px;
            overflow-y: auto;
            padding: 20px;
            box-sizing: border-box;
            border: 1px solid #f1f1f1;
            border-radius: 10px;
            h4 {
                font-size: 16px;
            }

            .db-subjects-list-container {
                display: flex;
                justify-content: flex-start;
                align-items: stretch;
                flex-wrap: wrap;
                .subject-card-container {
                    width: calc(100% * 1/3.0001 - 10px);
                    padding: 15px;
                    box-sizing: border-box;
                    border-radius: 10px;
                    margin: 5px;
                    margin-bottom: 5px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;

                    .db-l-left-side-section {
                        width: 100%;
                        margin-right: 10px;

                        h4 {
                            font-size: 16px;
                            margin: 0;
                            text-overflow: ellipsis;
                            width: 180px;
                            overflow: hidden;
                            white-space: nowrap;
                        }

                        h5 {
                            font-size: 12px;
                            margin: 0;
                        }

                        p {
                            font-size: 12px;
                            font-weight: 400;
                            margin: 2px 0 0;
                        }
                    }

                    .db-r-right-side-section {
                        max-width: 50px;

                        img {
                            width: 100%;
                            border-radius: 50%;
                        }
                    }

                    &::after {
                        content: "";
                        flex: auto;
                    }
                }
            }
        }

        .db-s-right-side-section {
            width: calc(100% - 715px);
            margin-left: 15px;
            height: 300px;
        }
    }

    .db-s-upcoming-events-container {
        height: 100%;
        min-height: 280px;
        background-color: #fff;
        box-shadow: 0.5pt 10px 14px -0.09375in rgba(25, 42, 70, 0.13);
        box-sizing: border-box;
        border: 1px solid #f1f1f1;
        border-radius: 10px;
        flex: 0 0 30%;
        h4 {
            font-size: 16px;
            padding: 20px 20px 0;
            box-sizing: border-box;
            margin-bottom: 0;
        }
    }

    .db-student-bottom-flex-container {
        display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		margin-bottom: 15px;
        flex-wrap: wrap;
        
        .db-s-left-side-section {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            min-width: 700px;
            flex-wrap: wrap;
            .db-attendance-percentange-graph {
                margin-right: 15px;
                background-color: #fff;
                box-shadow: 0.5pt 10px 14px -0.09375in rgba(25,42,70,.13);
                height: 280px;
                overflow-y: auto;
                width: 342.5px;
                padding: 20px;
                box-sizing: border-box;
                border: 1px solid #f1f1f1;
                border-radius: 10px;
                h4 {
                    font-size: 16px;
                }
            }
            .db-performance-percentange-graph {
                background-color: #fff;
                box-shadow: 0.5pt 10px 14px -0.09375in rgba(25,42,70,.13);
                width: 342.5px;
                height: 280px;
                overflow-y: auto;
                padding: 20px;
                box-sizing: border-box;
                border: 1px solid #f1f1f1;
                border-radius: 10px;
                margin-right: 15px;
                h4 {
                    font-size: 16px;
                }
        
                .labels-list-container {
                    display: flex;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    .label-data-container {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        margin-right: 10px;
                        margin-bottom: 10px;
                        .color-box {
                            width: 15px;
                            height: 15px;
                            border-radius: 15px;
                            margin-right: 5px;
                        }
                        p {
                            font-size: 9px;
                            font-weight: 600;
                            margin: 0;
                        }
                    }
                }
            }
        }

        .db-s-right-side-section {
            width: calc(100% - 715px);
        }
    }

    .db-s-top-numbers-list-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        .stat-card-container {
            margin-bottom: 15px;
            width: 19%;
            background-color: #fcfcfc;
            border: 1px solid #f1f1f1;
            border-radius: 10px;
            -webkit-box-shadow: 0.5pt 10px 14px -0.09375in rgba(25,42,70,.13);
            box-shadow: 0.5pt 10px 14px -0.09375in rgba(25,42,70,.13);
            padding: 10px 15px;
            box-sizing: border-box;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            &:hover {
                transform: translate(0,-4px);
            }
            .stat-left-side-section {
                width: 45px;
                // margin-right: 15px;
                background-color: #f1f1f1;
                border-radius: 50%;
                min-height: 45px;
                box-shadow: 0 2px 4px #e1e1e1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                svg {
                    width: 25px;
                    height: 25px;
                }
            }
            .stat-right-side-section {
                // width: calc(100% - 80px);
                margin-left: 10px;
                margin-top: 5px;
                h5 {
                    margin: 0;
                    font-size: 14px;
                    font-weight: 600;
                }
                h3 {
                    margin: 0;
                    font-size: 18px;
                }
            }
        }
    }

    .db-student-last-flex-container {
        display: flex;
		justify-content: space-between;
		align-items: flex-start;
		margin-bottom: 15px;

        .db-s-last-left-container {
            flex: 0 0 49%;
            width: calc(50% - 7.5px);
            height: 280px;
            background-color: #fff;
            box-shadow: 0.5pt 10px 14px -0.09375in rgba(25, 42, 70, 0.13);
            box-sizing: border-box;
            border: 1px solid #f1f1f1;
            border-radius: 10px;
            padding: 20px;

            h4 {
                font-size: 16px;
            }

            .db-s-quiz-status-container {
                overflow-y: auto;
                max-height: 215px;
                .quiz-status-row {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding-bottom: 15px;
                    padding-top: 15px;
                    border-bottom: 2px solid #f1f1f1;
                    
                    &:last-child {
                        border-bottom: none;
                    }
                    
                    .small-sec {
                        width: 100px;
                        margin-right: 10px;
                        .l-r-date {
                            font-size: 13px;
                            margin: 0;
                            text-align: center;
                        }
                    }
    
                    .big-sec {
                        margin-right: 10px;
                        width: 150px;
                        .l-r-name {
                            font-size: 13px;
                            margin: 0;
                        }
                    }
                    
                    .action-sec {
                        .l-r-approve {
                            margin-right: 10px;
                            border: none;
                            background-color: #36b37e;
                            font-size: 10px;
                            border-radius: 30px;
                            height: 25px;
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            padding: 0 10px;
                            font-weight: 600;
                            font-family: 'gotham-rounded', 'helvetica neue' ,helvetica;
                            i {
                                padding-left: 5px;
                            }
                        }
                    }
    
                    .large-sec {
                        width: 300px;
                        
                        .l-r-query {
                            font-size: 12px;
                        }
                    }
                }
            }
        }

        .db-s-last-right-container {
            flex: 0 0 49%;
            width: calc(50% - 7.5px);
            height: 280px;
            background-color: #fff;
            box-shadow: 0.5pt 10px 14px -0.09375in rgba(25, 42, 70, 0.13);
            box-sizing: border-box;
            border: 1px solid #f1f1f1;
            border-radius: 10px;
            padding: 20px;
            overflow-y: auto;
            h4 {
                font-size: 16px;
            }

            .db-s-doubt-status-container {
                .doubt-status-row {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding-bottom: 15px;
                    padding-top: 15px;
                    border-bottom: 2px solid #f1f1f1;
    
                    &:last-child {
                        border-bottom: none;
                    }
    
                    .big-sec {
                        margin-right: 10px;
                        width: 250px;
                        .l-r-name {
                            font-size: 13px;
                            margin: 0;
                        }
                    }

                    .small-sec {
                        width: 100px;
                        margin-right: 10px;
                        .l-r-date {
                            font-size: 13px;
                            margin: 0;
                            text-align: center;
                        }
                    }
                    
                    .action-sec {
                        .l-r-approve {
                            margin-right: 10px;
                            border: none;
                            background-color: #36b37e;
                            font-size: 10px;
                            border-radius: 30px;
                            height: 25px;
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            padding: 0 10px;
                            font-weight: 600;
                            font-family: 'gotham-rounded', 'helvetica neue' ,helvetica;
                            i {
                                padding-left: 5px;
                            }
                        }
                        .l-r-reject {
                            margin-right: 10px;
                            border: none;
                            background-color: #f4d5d1;
                            font-size: 10px;
                            border-radius: 30px;
                            height: 25px;
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            padding: 0 10px;
                            font-weight: 600;
                            font-family: 'gotham-rounded', 'helvetica neue' ,helvetica;
                            i {
                                padding-left: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) and (min-width: 767px) {
    .app-dashboard-secondary-container {
        .dashboard-main-container {
            padding: 20px 0;
            .db-student-top-flex-container {
                flex-wrap: wrap;

                .db-s-left-side-section {
                    flex: 0 0 100%;
                    max-width: unset;
                    margin-bottom: 15px;
                }

                .db-s-right-side-section {
                    flex: 0 0 100%;
                    margin-left: 0;
                }
            }

            .db-student-bottom-flex-container {
                flex-wrap: wrap;
                .db-s-left-side-section {
                    flex: 0 0 100%;
                    justify-content: space-between;
                    .db-attendance-percentange-graph {
                        width: 49%;
                        margin-right: 0;
                    }

                    .db-performance-percentange-graph {
                        width: 49%;
                        margin-right: 0;
                    }
                }
                
                .db-s-right-side-section {
                    flex: 0 0 100%;
                    margin-top: 15px;
                    margin-left: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .app-dashboard-secondary-container {
        .dashboard-main-container {
            padding: 20px 10px 0;
            .db-student-top-flex-container {
                flex-wrap: wrap;

                .db-s-left-side-section {
                    flex: 0 0 100%;
                    max-width: unset;
                    margin-bottom: 15px;
                    min-width: unset;
                    padding: 10px;
                    height: auto;

                    .db-subjects-list-container {
                        .subject-card-container {
                            width: calc(100% * 1/2.0001 - 10px);

                            .db-l-left-side-section {
                                h4 {
                                    width: 150px;
                                }
                            }
                        }
                    }
                }

                .db-s-right-side-section {
                    flex: 0 0 100%;
                    margin-left: 0;
                }
            }

            .db-student-bottom-flex-container {
                flex-wrap: wrap;
                .db-s-left-side-section {
                    flex: 0 0 100%;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    min-width: unset;
                    .db-attendance-percentange-graph {
                        width: 100%;
                        margin-right: 0;
                        margin-bottom: 15px;
                    }

                    .db-performance-percentange-graph {
                        width: 100%;
                        margin-right: 0;
                        margin-bottom: 15px;
                    }
                }
                
                .db-s-right-side-section {
                    flex: 0 0 100%;
                    margin-top: 15px;
                    margin-left: 0;
                }
            }

            .db-s-top-numbers-list-container {
                .stat-card-container {
                    width: 49%;
                }
            }

            .db-student-last-flex-container {
                flex-wrap: wrap;
                .db-s-last-left-container {
                    flex: 0 0 100%;
                    margin-bottom: 15px;
                }

                .db-s-last-right-container {
                    flex: 0 0 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 360px) {
    .app-dashboard-secondary-container .dashboard-main-container .db-student-top-flex-container .db-s-left-side-section .db-subjects-list-container .subject-card-container .db-l-left-side-section h4 {
        width: 130px;
    }
}