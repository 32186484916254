.app-dashboard-details-main-container {
    .app-assignment-details-page {
        .assignment-details-page-container {
            .accordion-content-container {
                padding: 15px;

                .uk-accordion-title {
                    border: 1px solid #f1f1f1;
                }
            }
        }

        .add-assignment-form-container {
            .card-body {
                .form-group {
                    label {
                        font-size: 14px;
                        font-weight: 600;
                        color: #3e416c;
                    }

                    input, select {
                        height: 2.875pc;
                    }

                    .choose-file-section {
                        label {
                            background-color: #dddddd;
                            padding: 9px 15px;
                            margin-top: 0;
                            border-radius: 5px;
                            cursor: pointer;
                            margin-bottom: 0;
                            display: inline-block;
                        }
                        input {
                            display: none;
                        }
                    }
                }
            }

            .save-button-container {
                padding: 15px 30px;
            }
        }

        .view-assignment-submissions-container {
            .card-body {
                h6 {
                    font-size: 14px;
                    color: #3e4163;
                    font-weight: 600;
                }

                p {
                    font-size: 12px;
                    color: #616262;
                    font-weight: 600;
                }
            }
        }
    }
}