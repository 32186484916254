.nickname {
    background: rgba(58, 64, 74, 0.651);
    padding: 5px !important;
    position: absolute;
    z-index: 999;
    color: #ffffff;
}
.form-control {
    color: black;
}

.pointer {
    cursor: pointer;
}

.close-icon {
    padding-top: 5px;
    background-color: white;
    cursor: pointer;
}

#closeButton {
    position: absolute;
    top: -3px;
    right: 0;
    z-index: 999;
}

#name-error-text {
    color: #fd6d5f;
    font-weight: bold;
    text-align: center;
}

#nicknameForm {
    padding: 10px;
}
.fullscreen {
    top: 40px;
}

.streamComponent {
    height: 100%;
    position: relative;
    border: 1px solid #515252;

    .user-symbol-info-section {
        width: 100%;
        height: 100%;
        background-color: #333;
        position: absolute;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .usi-circle {
            width: 150px;
            height: 150px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #3e416c;
            border-radius: 50%;
            font-size: 43px;
            color: #fff;
        }
    }
}

video {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 100%;
    color: #ffffff;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-family: Arial, Helvetica, sans-serif;
}

#statusIcons {
    bottom: 0;
    background: #c71100;
    width: 40px;
    height: fit-content;
    position: absolute;
    color: #ffffff;
}

#camIcon,
#micIcon {
    text-align: center;
    padding: 6px;
}

#fullscreenButton {
    position: absolute;
    bottom: 1px;
    right: 1px;
    z-index: 1000;
    background-color: #000000c4;
}
#volumeButton {
    background-color: #000000c4;
    position: absolute;
    bottom: 45px;
    right: 1px;
    z-index: 1000;
    color: #ffffff;
}
/* Contains the video element, used to fix video letter-boxing */
.OT_widget-container {
    width: 100%;
    height: 100%;
    position: absolute;
    overflow: hidden;
}

#input,
#label {
    color: white;
}


.video-subscriber-info-section {
    position: absolute;
    top: 0;
    z-index: 999;
    background-color: rgba(58, 64, 74, 0.651);
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .status-icons-section {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .video-secion {
            padding: 5px 10px;
            background: #50b37e;
            color: #fff;
        }

        .mic-section {
            padding: 5px 10px;
            background: #ee5335;
            color: #fff;
        }
    }

    .nickname-info-section {
        padding-left: 10px;
        color: #f1f1f1;
        font-weight: 600;
        font-family: 'Raleway', 'helvetica neue' ,helvetica;
        padding-right: 15px;

    }
    
    i.uil-times-circle {
        font-size: 18px;
        color: #ee5335;
        margin-right: 10px;
        cursor: pointer;
    }
}