.app-dashboard-details-main-container {
    .chapters-list-main-container {
        padding: 15px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        
        .chapter-detail-card {
            width: calc(100% * 1 / 2.001 - 20px);
            margin: 10px;
            padding: 15px;
            border: 1px solid #f1f1f1;
            border-radius: 10px;
            margin-bottom: 15px;

            .chapter-details-flex-container {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .chapter-flex-list-left-side {
                    width: calc(100% - 90px);
                    .chapter-counts-list {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        flex-wrap: wrap;
                        span {
                            flex: 0 0 48%;
                            font-size: 12px;
                            font-weight: 600;
                            color: #3e416c;
                            display: inline-block;
                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }

                .go-to-button-section {
                    width: 80px;
                    text-align: center;

                    a {
                        display: flex;
                        justify-content: center;
                        align-items: center;                   
                    }
                    button {
                        outline: none;
                        cursor: pointer;
                        height: 50px;
                        width: 50px;
                        border: none;
                        border-radius: 50%;
                        font-size: 24px;
                        color: #3e416c;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }
    }

    .subject-chapter-details-container {
        .top-container {
            border: 1px solid #ddd;
            border-bottom: none;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;

            .chapter-heading {
                padding: 15px;

                h1 {
                    font-size: 24px;
                    margin: 0 0;
                }
            }

            ul {
                li {
                    a {
                        font-size: 14px;
                        font-weight: 600;
                        color: #3e416c;
                    }
                }
            }
        }

        .chapter-tab-content-container {
            padding: 15px;

            .topic-list-container {
                padding-top: 15px;
                ul {
                    list-style-type: none;
                    padding-left: 20px;

                    li {
                        font-size: 14px;
                        font-weight: 600;
                        color: #3e416c;

                        i {
                            padding-right: 5px;
                        }
                    }
                }
            }

            .chapter-topic-wise-details-container {
                min-height: 150px;
                border: 1px solid #c9c9d0;
                padding: 15px;
                margin-bottom: 15px;
                border-radius: 10px;
                .chapter-videos-slider-container {
                    margin: 0px;
                    padding: 0px;
                    li {
                        display: inline-block;
                        width: calc(100% * 1 /4.001 - 10px);
                        border: 1px solid #3e416c;
                        border-radius: 10px;
                        margin: 5px;
                    }
                    .chapter-video-card {
                        .icon-feather-message-square {
                            margin-top: 5px;
                        }
                        .chapter-card-image {
                            text-align: center;

                            img {
                                width: auto;
                                height: 100%;
                            }
                        }
                        .actions {
                            display: flex;
                            margin-top: 8px;
                            a {
                                margin-top: 3px;
                                i {
                                        font-size: 18px;
                                    }
                            }
                            button {
                                margin: 0 2px !important;
                                height: 22px;
                            }
                            .btn-primary {
                                border-color: #3e416c !important;
                                background-color: #3e416c !important;
                            }
                        }
                    }

                }
            }

        }
    }
}

@media screen and (max-width: 767px) {
    .app-dashboard-details-main-container {
        .chapters-list-main-container {
            .chapter-detail-card {
                width: 100%;

                .chapter-details-flex-container {
                    .chapter-flex-list-left-side  {
                        width: 100%;

                        h5 {
                            span {
                                display: block;
                            }
                        }
                    }
                }
            }
        } 
    }
}

.slide-nav-prev,
.slide-nav-next {
    color: white !important;
}

.col-form-label {
    font-size: 14px;
        color: #3e416c;
        font-weight: 600;
}