.app-dashboard-details-main-container {
    .social-posts-flex-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .scf-left-side {
            flex: 0 0 65%;
            padding: 20px;
            .upload-new-post-container {
                background-color: #fff;
                background-clip: border-box;
                border: 1px solid #eff2f7;
                padding: 15px;
                border-radius: .375rem;
                box-shadow: 0 7px 14px 0 rgba(65,69,88,.1), 0 3px 6px 0 rgba(0,0,0,.07);
                .upload-form {
                    .top-row {
                        label {
                            font-size: 16px;
                            color: #3e416c;
                            font-weight: 600;
                        }
                    }

                    .selected-file-section {
                        margin-bottom: 15px;
                        
                        .image-section {
                            position: relative;
                            max-width: 200px;
                            img {
                                max-width: 200px;
                                border-radius: 5px;
                            }

                            .remove-icon {
                                cursor: pointer;
                                width: 30px;
                                height: 30px;
                                position: absolute;
                                top: 0;
                                right: 0;
                                background-color: #ff5630;
                                color: #fff;
                                text-align: center;
                                border-top-right-radius: 4px;
                                border-bottom-left-radius: 4px;
                                border: 1px solid #ff5630;
                            }
                        }
                    }
                    .bottom-row {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;

                        .post-type-section {
                            flex: 0 0 70%;
                            display: flex;
                            justify-content: flex-start;
                            align-items: flex-start;
                            select {
                                flex: 0 0 40%;
                            }
                            .choose-file-section {
                                margin-left: 15px;

                                label {
                                    background-color: #dddddd;
                                    padding: 9px 15px;
                                    margin-top: 0;
                                    border-radius: 5px;
                                    cursor: pointer;
                                    margin-bottom: 0;
                                }
                                input {
                                    display: none;
                                }
                            }
                        }

                        .save-button-section {
                            button {
                                width: 150px;
                                padding:11px 15px;
                                background-color: #3e416c;
                                font-size: 14px;
                                border-radius: 5px;
                                border: none;
                                color: #fff;
                                font-weight: 600;
                                outline: none;
                            }
                        }

                    }
                }
            }

            .social-feed-list-container {
                .social-post-container {
                    margin-top: 15px;
                    background-color: #fff;
                    background-clip: border-box;
                    border: 1px solid #eff2f7;
                    padding: 15px;
                    border-radius: .375rem;
                    box-shadow: 0 7px 14px 0 rgba(65,69,88,.1), 0 3px 6px 0 rgba(0,0,0,.07);
                    .social-post-top-container {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .sftc-left-side {
                            .sftc-user-details {
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                img {
                                    border-radius: 50%;
                                    width: 40px;
                                    height: 40px;
                                }

                                .info-section {
                                    margin-left: 15px;
                                    h6 {
                                        margin: 0;
                                        font-size: 14px;
                                        font-weight: 600;
                                        color: #3e416c;
                                    }

                                    p {
                                        font-size: 10px;
                                        margin: 0;
                                        line-height: 12px;
                                    }
                                }
                            }
                        }
                    }

                    .social-post-body-container {
                        margin-top: 10px;
                        border-top: 1px solid #f1f1f1;
                        padding-top: 10px;

                        .post-desc {
                            font-size: 14px;
                            margin-bottom: 10px;
                            font-weight: 500;
                            line-height: 22px;
                        }

                        .post-bg-section {
                            background-color: #fafafa;

                            video {
                                width: 100%;
                            }
                        }
                    }

                    .social-post-footer-container {
                        margin-top: 10px;
                        border-top: 1px solid #f1f1f1;
                        padding-top: 10px;
                        .post-actions-container {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;

                            .likes-section {
                                width: 80px;

                                div {
                                    cursor: pointer;
                                    display: flex;
                                    align-items: center;
                                    font-size: 12px;
                                    font-weight: 600;

                                    i {
                                        padding-right: 5px;
                                        font-size: 16px;
                                    }

                                    span {
                                        padding-right: 2px;
                                    }
                                }

                            }

                            .comments-section {
                                width: 120px;

                                div {
                                    cursor: pointer;
                                    display: flex;
                                    align-items: center;
                                    font-size: 12px;
                                    font-weight: 600;

                                    i {
                                        padding-right: 5px;
                                        font-size: 16px;
                                    }

                                    span {
                                        padding-right: 2px;
                                    }
                                }

                            }

                            .flags-section {
                                width: 80px;

                                div {
                                    cursor: pointer;
                                    display: flex;
                                    align-items: center;
                                    font-size: 12px;
                                    font-weight: 600;

                                    i {
                                        padding-right: 5px;
                                        font-size: 16px;
                                    }

                                    span {
                                        padding-right: 2px;
                                    }
                                }

                            }
                        }

                        .post-comments-list-container {
                            margin-top: 10px;
                            border: 1px solid #f1f1f1;
                            padding: 15px;
                            border-radius: 10px;

                            .post-comments {
                                .comment-row {
                                    background: #fafafa;
                                    padding: 10px;
                                    border-bottom: 1px solid #f1f1f1;

                                    &:last-child {
                                        border: none;
                                    }
                                    h6 {
                                        font-size: 14px;
                                        margin: 0;
                                        font-weight: 600;
                                        color: #3e416c;
                                    }

                                    p {
                                        margin: 0;
                                        font-size: 12px;
                                        color: #414242;
                                        font-weight: 400;
                                        line-height: 20px;
                                    }

                                    .date-time {
                                        font-size: 10px;
                                        color: #414242;
                                        font-weight: 400;
                                        margin: 0;
                                    }
                                }
                            }
                        }

                        .comment-form-container {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-top: 10px;

                            .cfc-left-side {
                                width: calc(100% - 60px);

                                input {
                                    width: 100%;
                                    border: 1px solid #f1f1f1;
                                    border-radius: 5px;
                                    padding: 8px;
                                    margin-bottom: 0;
                                }
                            }

                            .cfc-right-side {
                                width: 50px;
                                button {
                                    width: 100%;
                                    border: none;
                                    border-radius: 5px;
                                    background-color: #3e416c;
                                    color: #fff;
                                    padding: 10px;
                                    font-size: 15px;
                                    font-weight: 600;
                                }
                            }
                        }
                    }
                }
            }
        }

        .scf-right-side {
            flex: 0 0 30%;
            padding-top: 20px;
        }
    }
}