.dashboard-main-container {
	padding: 15px;

	.db-faculty-top-row-flex-container {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		margin-bottom: 15px;
		.db-f-left-side-section {
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			flex: 0 0 65%;
			min-width: 700px;
			.db-attendance-percentange-graph {
				background-color: #fff;
				box-shadow: 0.5pt 10px 14px -0.09375in rgba(25,42,70,.13);
				width: calc(100% * 1/ 2.001);
				height: 280px;
				overflow-y: auto;
				max-width: 440px;
				padding: 20px;
				box-sizing: border-box;
				border: 1px solid #f1f1f1;
				border-radius: 10px;
				h4 {
					font-size: 16px;
				}
			}
			.db-performance-percentange-graph {
				background-color: #fff;
				box-shadow: 0.5pt 10px 14px -0.09375in rgba(25,42,70,.13);
				max-width: 440px;
				height: 280px;
				overflow-y: auto;
				padding: 20px;
				box-sizing: border-box;
				border: 1px solid #f1f1f1;
				border-radius: 10px;
				margin-right: 15px;
				h4 {
					font-size: 16px;
				}
		
				.labels-list-container {
					display: flex;
					justify-content: flex-start;
					flex-wrap: wrap;
					.label-data-container {
						display: flex;
						justify-content: flex-start;
						align-items: center;
						margin-right: 10px;
						margin-bottom: 10px;
						.color-box {
							width: 15px;
							height: 15px;
							border-radius: 15px;
							margin-right: 5px;
						}
						p {
							font-size: 9px;
							font-weight: 600;
							margin: 0;
						}
					}
				}
			}
		}

		.db-f-right-side-section {
			background-color: #fff;
			box-shadow: 0.5pt 10px 14px -0.09375in rgba(25,42,70,.13);
			height: 278px;
			width: calc(100% - 715px);
			margin-left: 15px;
			overflow-y: auto;
			padding: 20px;
			box-sizing: border-box;
			border: 1px solid #f1f1f1;
			border-radius: 10px;
			.db-f-top-numbers-list-container {
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex-wrap: wrap;
				.stat-card-container {
					margin-bottom: 10px;
					background-color: #fcfcfc;
					border: 1px solid #f1f1f1;
					width: 48%;
					min-width: 80px;
					border-radius: 10px;
					margin-right: 0.667%;
					-webkit-box-shadow: 0.5pt 10px 14px -0.09375in rgba(25,42,70,.13);
					box-shadow: 0.5pt 10px 14px -0.09375in rgba(25,42,70,.13);
					padding: 10px 15px;
					box-sizing: border-box;
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					align-items: center;
					&:hover {
						transform: translate(0,-4px);
					}
					.stat-left-side-section {
						width: 45px;
						// margin-right: 15px;
						background-color: #f1f1f1;
						border-radius: 50%;
						min-height: 45px;
						box-shadow: 0 2px 4px #e1e1e1;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						svg {
							width: 25px;
							height: 25px;
						}
					}
					.stat-right-side-section {
						// width: calc(100% - 80px);
						margin-top: 5px;
						h5 {
							margin: 0;
							font-size: 12px;
							font-weight: 600;
							text-align: center;
						}
						h3 {
							margin: 0;
							font-size: 18px;
							text-align: center;
						}
					}
				}
			}
		}
	}

	.db-faculty-bottom-row-flex-container {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;

		.db-f-left-side-section {
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			flex-wrap: wrap;
			width: 340px;
			.db-f-upcoming-events-container {
				min-width: 340px;
				height: 300px;
				background-color: #fff;
				box-shadow: 0.5pt 10px 14px -0.09375in rgba(25, 42, 70, 0.13);
				box-sizing: border-box;
				border: 1px solid #f1f1f1;
				border-radius: 10px;
				margin-right: 15px;
				flex: 0 0 30%;
				h4 {
					font-size: 16px;
					padding: 20px 20px 0;
					box-sizing: border-box;
					margin-bottom: 0;
				}
			}
		}
		.db-f-right-side-section {
			width: calc(100% - 355px);
			margin-left: 15px;
			.doubt-request-container {
				background-color: #fff;
				box-shadow: 0.5pt 10px 14px -0.09375in rgba(25,42,70,.13);
				width: 100%;
				padding: 20px;
				box-sizing: border-box;
				border: 1px solid #f1f1f1;
				border-radius: 10px;
				height: 300px;
				overflow-y: auto;
				h4 {
					font-size: 16px;
				}
		
				.doubt-request-list {
					.doubt-request-row {
						display: flex;
						justify-content: flex-start;
						align-items: center;
						padding-bottom: 15px;
						padding-top: 15px;
						border-bottom: 2px solid #f1f1f1;
		
						&:last-child {
							border-bottom: none;
						}
						.xs-sec {
							max-width: 40px;
							margin-right: 10px;
							.l-r-image {
								max-width: 40px;
								max-height: 40px;
								border-radius: 30px;
							}
						}
						
						.small-sec {
							width: 100px;
							margin-right: 10px;
							.l-r-date {
								font-size: 13px;
								margin: 0;
								text-align: center;
							}
						}
		
						.big-sec {
							margin-right: 10px;
							width: 125px;
							.l-r-name {
								font-size: 13px;
								margin: 0;
							}
						}
						
						.action-sec {
							.l-r-approve {
								margin-right: 10px;
								border: none;
								background-color: #36b37e;
								font-size: 10px;
								border-radius: 30px;
								height: 25px;
								display: inline-flex;
								justify-content: center;
								align-items: center;
								padding: 0 10px;
								font-weight: 600;
								font-family: 'gotham-rounded', 'helvetica neue' ,helvetica;
								i {
									padding-left: 5px;
								}
							}
						}
		
						.large-sec {
							width: 300px;
							
							.l-r-query {
								font-size: 12px;
							}
						}
					}
				}
			}
		}
	}

	.db-top-numbers-list-container {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: wrap;
		.stat-card-container {
			margin-bottom: 15px;
			width: 16%;
			background-color: #fcfcfc;
			border: 1px solid #f1f1f1;
			border-radius: 10px;
			margin-right: 0.667%;
			-webkit-box-shadow: 0.5pt 10px 14px -0.09375in rgba(25,42,70,.13);
			box-shadow: 0.5pt 10px 14px -0.09375in rgba(25,42,70,.13);
			padding: 10px 15px;
			box-sizing: border-box;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			&:hover {
				transform: translate(0,-4px);
			}
		}
	}
}