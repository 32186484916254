.app-dashboard-details-main-container {
    .ac-staff-list-main-container {
        .ac-staff-list-flex-container {
            padding: 15px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;

            .ac-staff-card-container {
                width: calc(100% * 1 / 3.001 - 20px);
                margin: 10px;
                border: 1px solid #f1f1f1;
                border-radius: 10px;
                margin: 10px;
                position: relative;
                background-color: #fafafa;

                .ac-staff-card-top-container {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 15px;
                    .left-side {
                        width: 60px;
                        margin-right: 25px;
                        .image-section {
                            width: 50px;
                            height: 50px;
                            margin: auto;
                            background-color: #ffffff80;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 50%;
                            img {
                                width: 100%;
                                border-radius: 50%;
                            }
                        }
                        
                    }
    
                    .right-side {
                        h5 {
                            margin: 0;
                            font-size: 14px;
                            a {
                                color: #3e416c;
                            }
                        }
    
                        p {
                            font-size: 12px;
                            color: #3e416c;
                            font-weight: 600;
                            margin: 0;
                            a {
                                color: #3e416c;
                                font-weight: 600;
                            }
    
                            small {
                                color: #3e416c;
                                font-weight: 600;
                            }
                        }
                    }

                    p.subject-link {
                        font-size: 11px;
                        cursor: pointer;
                        color: #3c9be2;
                    }
                }

                
    
                .ac-staff-card-bottom-container {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background-color: rgba(62, 65, 108, 0.85);
                    padding: 2px 5px;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
    
                    div {
                        flex: 0 0 50%;
                        text-align: center;
                        color: #fff;
                        font-size: 10px;
                        font-weight: 600;
                        padding: 3px;
                        cursor: pointer;
                        i {
                            padding-right: 5px;
                        }
                        &:first-child {
                            border-right: 1px solid #ddd;
                        }
                    }
                }
            }
        }
    }

    .add-staff-form-page-container {
        .add-staff-form-section {
            padding: 15px 15px;
            .first-row, .second-row {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;

                .form-group-section {
                    width: calc(100% * 1 / 2.001 - 20px);
                    margin: 10px;

                    .form-group {
                        margin-bottom: 0;

                        input, select {
                            margin-bottom: 0;
                        }

                        select {
                            height: 2.875pc;
                        }
                        label {
                            font-size: 14px;
                            font-weight: 600;
                            color: #3e416c;
                            margin-bottom: 5px;
                        }
                    }

                    button {
                        background-color: #3e416c;
                        color: #fff;
                    }
                }
            }
        }

        .staff-edit-accordion-container {
            padding: 15px;
            .uk-accordion-title {
                border-radius: 5px;
                border: 1px solid #f1f1f1;
            }

            .uk-open .uk-accordion-title {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
            .permissions-accordion-content {
                padding: 15px;

                table {
                    tr.table-header-row {
                        th {
                            padding: 8px 10px;
                            background-color: #3e416c;
                            font-size: 14px;
                            font-weight: 600;
                            color: #fff;
                            border: none;

                            &:first-child {
                                border-top-left-radius: 10px;
                            }

                            &:last-child {
                                border-top-right-radius: 10px;
                            }
                        }
                    }

                    tr.table-data-row {
                        td {
                            font-size: 12px;
                            color: #414242;
                            font-weight: 600;
                            small {
                                font-size: 12px;
                                color: #414242;
                                font-weight: 600;
                            }

                            input[type=checkbox] {
                                width: 20px !important;
                                height: 20px !important;
                                
                            }
                            input[type=checkbox]:checked {
                                background-color: #3e416c;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .app-dashboard-details-main-container {
        .ac-staff-list-main-container {
            .ac-staff-list-flex-container {
                padding: 15px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
    
                .ac-staff-card-container {
                    width: calc(100% * 1 / 2.001 - 20px);
                }
            }
        }
    }
}