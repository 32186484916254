.app-dashboard-details-main-container {
    .classes-list-flex-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        padding: 15px;
        .class-details-card-container {
            width: calc(100% * 1 / 3.001 - 20px);
            border: 1px solid #f1f1f1;
            border-radius: 10px;
            margin: 10px;
            position: relative;
            background-color: #fafafa;
            .cdc-top-container {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 15px;
                .cdc-left-side {
                    width: 45%;
                    margin-right: 25px;

                    .standard-heading {
                        text-align: center;
                        font-size: 12px;
                        font-weight: 600;
                        color: #3e416c;
                    }
                    .standard-info {
                        width: 50px;
                        height: 50px;
                        margin: auto;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        p {
                            font-size: 24px;
                            font-weight: 600;
                            color: #3e416c;
                        }
                    }

                    .edit-button-container {
                        text-align: center;
                        button {
                            background-color: #3c9be2;
                            color: #fff;
                        }
                    }
                    
                }

                .cdc-right-side {
                    h5 {
                        margin: 0;
                        font-size: 14px;
                        a {
                            color: #3e416c;

                            i {
                                font-size: 14px;
                                padding-left: 5px;
                            }
                        }
                    }

                    p {
                        font-size: 12px;
                        color: #3e416c;
                        font-weight: 600;
                        margin: 0;
                        a {
                            color: #3e416c;
                            font-weight: 600;
                        }

                        small {
                            color: #3e416c;
                            font-weight: 600;
                            min-width: 70px;
                            display: inline-block;
                        }
                    }

                    p.subject-link {
                        font-size: 10px;

                        a {
                            color: #3c9be2;
                        }
                    }
                }
            }

            .cdc-bottom-contianer {
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: rgba(62, 65, 108, 0.85);
                padding: 2px 5px;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;

                div {
                    flex: 0 0 50%;
                    text-align: center;
                    &:first-child {
                        border-right: 1px solid #ddd;
                    }
                    a {
                        color: #fff;
                        font-size: 10px;
                        font-weight: 600;

                        i {
                            padding-right: 5px;
                        }
                    }
                }
            }
        }
    }

    .attendace-details-container {
        padding: 15px;

        table {
            tr.table-header-row {
                th {
                    background-color: #3e416c;
                    padding: 8px 15px;
                    color: #fff;
                    font-size: 14px;
                    border: none;

                    &:first-child {
                        // background-color: #3c9be2;
                        border-top-left-radius: 10px;
                    }
                }
            }

            tr.table-header-second-row{
                th {
                    font-size: 12px;
                    color: #414242;
                    font-weight: 600;
                }
            }

            tr.table-data-row {
                td {
                    input[type=checkbox] {
                        width: 20px !important;
                        height: 20px !important;
                    }
                }
            }
        }
    }

    .add-period-for-class-container {
        padding: 15px;

        table.add-period-for-classs-table {
            .table-header-row {
                th {
                    background-color: #3e416c;
                    padding: 8px 15px;
                    text-align: center;
                    color: #fff;
                    font-size: 14px;

                    &:first-child {
                        border-top-left-radius: 10px;
                        text-align: left;
                        width: 150px;
                    }

                    &:last-child {
                        border-top-right-radius: 10px;
                    }

                    button {
                        background-color: #3c9be2;
                        border-radius: 5px;
                        color: #fff;
                    }
                }
            }

            .table-data-row {
                td {
                    input, select {
                        font-size: 12px;
                        font-weight: 600;
                        height: 35px;
                    }
                }
            }
        }
    }

    .classes-details-main-container {
        padding: 15px;

        .class-details-tabs-container {
            .nav.nav-tabs {
                .nav-item {
                    font-size: 14px;
                    font-weight: 600;

                    a {
                        color: #3e416c;
                        i {
                            padding-right: 5px;
                        }
                    }

                    a.active {
                        background-color: #3e416c;
                        color: #fff;
                    }
                }
            }

        }
        .db-p-class-teacher-details {
            p {
                font-size: 14px;
                color: #3e416c;
                font-weight: 600;
            }   
        }
    }
}


@media screen and (max-width: 1024px) {
    .app-dashboard-details-main-container {
        .classes-list-flex-container {
            .class-details-card-container {
                width: calc(100% * 1 / 2.001 - 20px);
            }
        }
    }
}