.dashboard-main-container {
    padding: 15px;

    .main-heading {
        font-size: 18px;
    }

    .db-ch-top-flex-container {
        margin: 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        .left-revenue-container {
            background-color: #fff;
            box-shadow: 0.5pt 10px 14px -0.09375in rgba(25,42,70,.13);
            width: 600px;
            height: 350px;
            padding: 20px;
            box-sizing: border-box;
            border: 1px solid #f1f1f1;
            border-radius: 10px;
            h4 {
                font-size: 16px;
            }
        }

        .right-revenue-container {
            background-color: #fff;
            box-shadow: 0.5pt 10px 14px -0.09375in rgba(25,42,70,.13);
            width: calc(100% - 620px);
            min-width: 300px;
            height: 350px;
            padding: 20px;
            box-sizing: border-box;
            border: 1px solid #f1f1f1;
            border-radius: 10px;
            h4 {
                font-size: 16px;
            }

            .labels-list-container {
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                .label-data-container {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin-right: 10px;
                    margin-bottom: 10px;
                    .color-box {
                        width: 15px;
                        height: 15px;
                        border-radius: 15px;
                        margin-right: 5px;
                    }
                    p {
                        font-size: 9px;
                        font-weight: 600;
                        margin: 0;
                    }
                }
            }
        }
    }

    .db-ch-bottom-flex-container {
        margin: 15px 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        
        .stat-card-container {
			margin-bottom: 15px;
            width: 19%;
            // min-width: 200px;
			background-color: #fcfcfc;
			border: 1px solid #f1f1f1;
			border-radius: 10px;
			margin-right: 1%;
			-webkit-box-shadow: 0.5pt 10px 14px -0.09375in rgba(25,42,70,.13);
			box-shadow: 0.5pt 10px 14px -0.09375in rgba(25,42,70,.13);
			padding: 10px 15px;
			box-sizing: border-box;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			&:hover {
				transform: translate(0,-4px);
            }
            
            .stat-left-side-section {
                width: 50px;
                margin-left: 15px;
                background-color: #f1f1f1;
                border-radius: 50%;
                min-height: 50px;
                box-shadow: 0 2px 4px #e1e1e1;
                display: flex;
                justify-content: center;
                align-items: center;
                svg {
                    width: 25px;
                    height: 25px;
                }
            }
            .stat-right-side-section {
                width: calc(100% - 60px);
                h5 {
                    margin: 0;
                    font-size: 14px;
                    font-weight: 400;
                }
                h3 {
                    margin: 0;
                    font-size: 20px;
                }
            }
        }
    }
}