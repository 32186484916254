.app-dashboard-details-main-container {
    .classes-details-main-container {
        .ac-staff-list-flex-container {
            padding: 15px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;

            .ac-staff-card-container {
                width: calc(100% * 1 / 3.001 - 20px);
                margin: 10px;
                border: 1px solid #f1f1f1;
                border-radius: 10px;
                margin: 10px;
                position: relative;
                background-color: #fafafa;

                .ac-staff-card-top-container {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 15px;
                    .left-side {
                        width: 60px;
                        margin-right: 25px;
                        .image-section {
                            width: 50px;
                            height: 50px;
                            margin: auto;
                            background-color: #ffffff80;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 50%;
                            img {
                                width: 100%;
                                border-radius: 50%;
                            }
                        }
                        
                    }
    
                    .right-side {
                        h5 {
                            margin: 0;
                            font-size: 14px;
                            a {
                                color: #3e416c;
                            }
                        }
    
                        p {
                            font-size: 12px;
                            color: #3e416c;
                            font-weight: 600;
                            margin: 0;
                            a {
                                color: #3e416c;
                                font-weight: 600;
                            }
    
                            small {
                                color: #3e416c;
                                font-weight: 600;
                            }
                        }
                    }

                    p.subject-link {
                        font-size: 11px;
                        cursor: pointer;
                        color: #3c9be2;
                    }
                }

                
    
                .ac-staff-card-bottom-container {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background-color: rgba(62, 65, 108, 0.85);
                    padding: 2px 5px;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
    
                    div {
                        flex: 0 0 50%;
                        text-align: center;
                        color: #fff;
                        font-size: 10px;
                        font-weight: 600;
                        padding: 3px;
                        cursor: pointer;
                        i {
                            padding-right: 5px;
                        }
                        &:first-child {
                            border-right: 1px solid #ddd;
                        }
                    }
                }
            }
        }

    }

    .app-class-performance-main-container {
        .acp-top-flex-container {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom: 20px;
            .acp-top-left-side {
                min-width: 600px;
                .acp-test-performance-graph {
                    background-color: #fff;
                    box-shadow: 0.5pt 10px 14px -0.09375in rgba(25,42,70,.13);
                    max-width: 580px;
                    padding: 20px;
                    box-sizing: border-box;
                    border: 1px solid #f1f1f1;
                    border-radius: 10px;
                    h4 {
                        font-size: 16px;
                    }
                }
            }
            .acp-top-right-side {
                width: calc(100% - 600px);
                .acp-top-right-stats-container {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    width: 100%;
    
                    .stat-card-container {
                        width: calc(100% * 1 / 2.001 - 20px);
                        margin: 10px;
                        border: 1px solid #f1f1f1;
                        border-radius: 10px;
                        margin: 10px;
                        position: relative;
                        background-color: #fafafa;
                        border-radius: 10px;
                        margin-right: 0.667%;
                        -webkit-box-shadow: 0.5pt 10px 14px -0.09375in rgba(25,42,70,.13);
                        box-shadow: 0.5pt 10px 14px -0.09375in rgba(25,42,70,.13);
                        padding: 10px 15px;
                        box-sizing: border-box;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        &:hover {
                            transform: translate(0,-4px);
                        }

                        .stat-left-side-section {
                            width: 50px;
                            margin-right: 15px;
                            background-color: #f1f1f1;
                            border-radius: 50%;
                            min-height: 50px;
                            box-shadow: 0 2px 4px #e1e1e1;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            svg {
                                width: 25px;
                                height: 25px;
                            }
                        }
                        .stat-right-side-section {
                            width: calc(100% - 80px);
                            h5 {
                                margin: 0;
                                font-size: 14px;
                                font-weight: 600;
                            }
                            h3 {
                                margin: 0;
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }
        
        .acp-bottom-flex-container {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom: 20px;
            .acp-top-left-side {
                min-width: 600px;
                .acp-test-performance-graph {
                    background-color: #fff;
                    box-shadow: 0.5pt 10px 14px -0.09375in rgba(25,42,70,.13);
                    max-width: 600px;
                    padding: 20px;
                    box-sizing: border-box;
                    border: 1px solid #f1f1f1;
                    border-radius: 10px;
                    h4 {
                        font-size: 16px;
                    }
                }
            }
            .acp-top-right-side {
                width: calc(100% - 615px);
                margin-right: 15px;
                .acp-top-right-stats-container {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    width: 100%;
    
                    .stat-card-container {
                        width: calc(100% * 1 / 2.001 - 20px);
                        margin: 10px;
                        border: 1px solid #f1f1f1;
                        border-radius: 10px;
                        margin: 10px;
                        position: relative;
                        background-color: #fafafa;
                        border-radius: 10px;
                        margin-right: 0.667%;
                        -webkit-box-shadow: 0.5pt 10px 14px -0.09375in rgba(25,42,70,.13);
                        box-shadow: 0.5pt 10px 14px -0.09375in rgba(25,42,70,.13);
                        padding: 10px 15px;
                        box-sizing: border-box;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        &:hover {
                            transform: translate(0,-4px);
                        }

                        .stat-left-side-section {
                            width: 50px;
                            margin-right: 15px;
                            background-color: #f1f1f1;
                            border-radius: 50%;
                            min-height: 50px;
                            box-shadow: 0 2px 4px #e1e1e1;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            h5 {
                                margin: 0;
                                color: #333;
                            }
                        }
                        .stat-right-side-section {
                            width: calc(100% - 80px);
                            p {
                                margin: 0;
                                font-size: 14px;
                                font-weight: 600;
                                color: #3e416c;
                            }
                            h3 {
                                margin: 0;
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .app-dashboard-details-main-container {
        .app-class-performance-main-container {
            .acp-top-flex-container {
                .acp-top-left-side {
                    flex: 0 0 100%;
                    .acp-test-performance-graph {
                        max-width: unset;
                    }
                }

                .acp-top-right-side {
                    flex: 0 0 100%;
                }
            }

            .acp-bottom-flex-container {
                flex: 0 0 100%;
                flex-wrap: wrap;
                flex-direction: column-reverse;
                .acp-top-right-side {
                    flex: 0 0 100%;
                    width: 100%;
                    margin-right: 0;
                    margin-left: 0;
                }

                .acp-top-left-side {
                    width: 100%;
                    .acp-test-performance-graph {
                        max-width: unset;
                        margin: 10px;
                    }
                }
            }
        }
        .classes-details-main-container {
            .ac-staff-list-flex-container {
                padding: 0;
                .ac-staff-card-container {
                    width: calc(100% * 1 / 2.001 - 20px);
                }
            }
        }
    }
}