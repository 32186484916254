.app-dashboard-details-main-container {
    .app-gallery-list-container {
        padding: 15px;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-wrap: wrap;

        .app-gallery-details-container {
            width: calc(100% * (1 / 2.001) - 20px);
            margin: 10px;
            border: 1px solid #f1f1f1;
            border-radius: 10px;
            background-color: #fff;

            .agd-top-info-section {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 15px;
                background-color: #fafafa;

                h5 {
                    margin: 0;
                    font-size: 16px;
                }

                .action-items {
                    button {
                        border: none;
                        border-radius: 5px;
                        color: #fff;
                        font-size: 10px;
                        font-weight: 600;
                        padding: 3px 10px;
                        
                        i {
                            padding-right: 5px;
                        }
                        &:first-child {
                            margin-right: 10px;
                            background-color: #3e416c;
                        }

                        &:last-child {
                            background-color: #3c9be2;
                        }
                    }
                }
            }

            .gallery-body-container {
                padding: 15px;
                background-color: #fff;

                p {
                    font-size: 14px;
                    font-weight: 500;
                    margin-bottom: 10px;
                }

                .gallery-image-video-container {
                    background: #fafafa;
                    width: 100%;
                    border-radius: 10px;
                    text-align: center;
                    margin-bottom: 5px;

                    img, video {
                        margin: auto;
                        max-width: 100%;
                        height: auto;
                        border-radius: 10px;
                    }
                }

                .gallery-image-carousel {
                    background-color: #fff;
                    .uk-slider-container {
                        .uk-slider-items {
                            li {
                                display: inline-block;
                                width: calc(100% * 1 / 3.001 - 20px);
                                margin: 10px;
                                padding: 0;
                                min-height: 100px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                background-color: #fafafa;
                                padding: 10px;
                                border-radius: 10px;
                                border: 1px solid #f1f1f1;

                                &:first-child {
                                    margin-left: 0;
                                }

                                div.relative {
                                    height: 100%;
                                    text-align: center;

                                    .top-right-delete-icon {
                                        top: -7px;
                                        right: -7px;
                                        cursor: pointer;
                                        position: absolute;
                                        width: 20px;
                                        height: 20px;
                                        border-radius: 50%;
                                        background: #ff5630;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        font-size: 10px;

                                        i {
                                            color: #fff !important;
                                        }
                                    }
                                    img, video {
                                        // width: 100%;
                                        max-height: 75px;
                                        border-radius: 5px;
                                    }
                                }
                            }
                            
                        }

                        .uk-slider-items.uk-grid {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .app-dashboard-details-main-container {
        .app-gallery-list-container {
            padding: 15px;
            display: flex;
            justify-content: space-between;
            align-items: stretch;
            flex-wrap: wrap;
    
            .app-gallery-details-container {
                width: 100%
            }
        }
    }
}