.app-dashboard-details-main-container {
    .library-books-container{
        padding: 15px;

        .lib-book-section-header {
            font-size: 16px;
            background-color: #3e416c;
            padding: 10px;
            font-weight: 600;
            color: #fff;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }

        .lib-class-books-list-container {
            border: 1px solid #f1f1f1;
            padding: 15px;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;

            .class-books-main-container {
                margin-bottom: 15px;
                .class-heding {
                    font-size: 16px;
                    font-weight: 600;
                    border-bottom: 1px solid #fafafa;
                    padding-bottom: 10px;
                    margin-bottom: 15px;
                }

                .class-books-list-flex-container {
                    display: flex;
                    justify-content: flex-start;
                    align-items: stretch;
                    align-content: flex-start;

                    .class-book-card {
                        flex: 0 0 20%;
                        margin-right: 1%;
                        box-shadow: 1px 1px 4px #ddd;
                        border-radius: 10px;

                        .book-image-section {
                            background-color: #ddd;
                            text-align: center;
                            padding: 15px 10px;
                            border-top-left-radius: 10px;
                            border-top-right-radius: 10px;
                        }

                        .book-details-section {
                            padding: 10px;

                            h4 {
                                margin: 0;
                                font-size: 14px;
                                cursor: pointer;
                            }

                            p {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .app-dashboard-details-main-container {
        .library-books-container{
            padding: 15px;
            .lib-class-books-list-container {
                .class-books-main-container {
                    margin-bottom: 15px;
    
                    .class-books-list-flex-container {
                        flex-wrap: wrap;
                        .class-book-card {
                            flex: 0 0 31%;
                            margin-right: 2.3%;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .app-dashboard-details-main-container {
        .library-books-container{
            padding: 15px;
            .lib-class-books-list-container {
                .class-books-main-container {
                    margin-bottom: 15px;
    
                    .class-books-list-flex-container {
                        flex-wrap: wrap;
                        .class-book-card {
                            flex: 0 0 48%;
                            margin-right: 2%;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
}