.app-home-page--conatiner {
  background-color: #fff;
  min-height: 100vh;

  .landing--section {
    height: 550px;
    background-color: #3e416c;

    .ls-flex--section {
      max-width: 85%;
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .ls--lside {
        flex: 0 0 40%;
        .app--ladning-heading {
          font-size: 28px;
          color: #f1f1f1;
        }

        .app--landing-desc {
          color: #ddd;
          font-size: 18px;
          font-family: 'Raleway', sans-serif;
          font-weight: 400;
        }

        .request-demo-button {
          margin-top: 30px;
          background-color: #3c9be2;
          padding: 10px 35px;
          border-radius: 5px;
          border: 2px solid #3c9be2;
          color: #fff;
          font-size: 18px;
          font-weight: 600;
          font-family: 'Raleway', 'helvetica neue' ,helvetica;
          cursor: pointer;
          outline: none;
          margin-bottom: 30px;
        }
      }

      .ls--rside {
        flex: 0 0 65%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        height: 625px;

        img {
          max-width: 700px;
        }
      }
    }
  }

  .webinar--section {
    .webinar--flex-section {
      height: 350px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 85%;
      margin: auto;
      background-color: #fafafa;
      border-radius: 20px;
      padding: 40px;
      position: relative;
      box-shadow: 0 3px 9px #e1e1e1;

      .webinar--lside {
        width: calc(100% - 700px);
        .wls--heading {
          font-size: 28px;
          color: #3e416c;
          text-transform: unset;
        }

        .wls--desc {
          font-size: 18px;
          color: #717272;
          font-family: 'Raleway', sans-serif;
          font-weight: 400;
        }

        .create-webinar-button {
          display: inline-block;
          margin-top: 30px;
          background-color: #3c9be2;
          padding: 10px 35px;
          border-radius: 5px;
          border: 2px solid #3c9be2;
          color: #fff;
          font-size: 18px;
          font-weight: 600;
          font-family: 'Raleway', 'helvetica neue' ,helvetica;
          cursor: pointer;
          outline: none;
        }
      }

      .webinar--rside {
        flex: 0 0 25%;

        img {
          position: absolute;
          bottom: 0;
          max-height: 350px;
          max-width: 700px;
          right: 0px;
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
        }
      }
    }
  }

  .features--section {
    padding-top: 150px;
    padding-bottom: 100px;

    .fs--flex-container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      max-width: 85%;
      margin: auto;

      .fs--lside {
        flex: 0 0 40%;

        .fs--heading {
          font-size: 28px;
          color: #3e416c;
          text-transform: unset;
        }

        .fs--desc {
          font-size: 18px;
          color: #717272;
          font-family: 'Raleway', sans-serif;
          font-weight: 400;
        }

        .fs--list-section {
          margin-top: 25px;
          .feature--list-card {
            margin-bottom: 15px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            background-color: #fff;
            border-radius: 10px;
            box-shadow: 0px 3px 9px #e1e1e1;
            border: 2px solid #fff;
            padding: 10px;
            min-width: 250px;
            max-width: 400px;
            cursor: pointer;
            position: relative;

            img {
              width: 45px;
              height: 45px;
            }

            h4 {
              font-size: 18px;
              color: #3e416c;
              font-family: 'Raleway', sans-serif;
              font-weight: 500;
              margin: 0;
              margin-left: 15px;
            }


            .check-mark-section {
              img {
                width: 30px;
                height: 30px;
                position: absolute;
                right: 15px;
                top: 18px
              }
            }
          }

          .feature--list-card.active {
            border-color: #3c9be2;
          }
        }
      }

      .fs--rside {
        flex: 0 0 55%;
        .fs--list-flex {
          display: flex;
          justify-content: space-between;
          align-items: stretch;
          flex-wrap: wrap;

          .feature--card--info {
            width: calc((100% * 1 / 2.001) - 20px);
            margin: 10px;
            display: flex;
            align-items: flex-start;
            padding: 10px;
            border-radius: 10px;
            box-shadow: 0 3px 9px #f1f1f1;

            .fci--lside {
              flex: 0 0 60px;

              img {
                width: 50px;
                height: 50px;
              }
            }

            .fci--rside {
              .fci--heading {
                font-size: 18px;
                color: #3e416c;
                font-family: 'Raleway', sans-serif;
                font-weight: 600;
                margin-bottom: 5px;
              }

              .fci--desc {
                font-size: 15px;
                color: #717272;
                font-family: 'Raleway', sans-serif;
                font-weight: 500;
                margin-bottom: 5px;
              }
            }
          }
        }
      }
    }
  }

  .new-way-of-learing--section {
    padding-top: 60px;
    padding-bottom: 60px;
    .nwl--flex-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 85%;
      margin: auto;

      .nsw--lside {
        flex: 0 0 40%;
      }

      .nsw--rside {
        flex: 0 0 55%;

        .nsw--heading {
          font-size: 28px;
          color: #3e416c;
          text-transform: unset;
        }

        .nsw--desc {
          font-size: 18px;
          color: #717272;
          font-family: 'Raleway', sans-serif;
          max-width: 90%;
          font-weight: 400;
        }

        .tech--flex-section {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          margin-top: 30px;

          .tech--card-section {
            width: calc((100% * 1 / 2.001) - 20px);
            box-shadow: 0px 3px 9px #e1e1e1;
            padding: 15px;
            border-radius: 15px;
            margin: 10px;

            .image--section {
              margin-top: 10px;
              margin-bottom: 10px;
              text-align: center;
              img {
                width: 65px;
                height: 65px;
              }
            }

            .content--section {
              .card--heading {
                font-size: 18px;
                margin-bottom: 5px;
                text-align: center;
                font-family: 'Raleway', sans-serif;
                color: #3c9be2;
              }
            }
          }
        }
      }
    }
  }

  .language--info-section {
    padding-top: 60px;
    padding-bottom: 60px;
    background-color: #fafafa;

    .li--flex-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 85%;
      margin: auto;

      .li--lside {
        flex: 0 0 60%;

        .li--heading {
          font-size: 28px;
          color: #3e416c;
          text-transform: unset;
        }

        .li--desc {
          font-size: 18px;
          color: #717272;
          font-family: 'Raleway', sans-serif;
          max-width: 90%;
          font-weight: 400;
        }

        .li--list-conatiner {
          display: flex;
          align-items: center;
          margin-top: 30px;

          ul {
            list-style-type: none;
            padding-left: 0;
            min-width: 250px;
            margin-right: 40px;

            li {
              font-size: 18px;
              padding-bottom: 10px;
              margin-bottom: 10px;
              border-bottom: 1px solid #f1f1f1;
              font-family: 'Raleway', sans-serif;
              font-weight: 600;
              color: #3e416c;
              
              span {
                padding-left: 10px;
                color: #3c9be2;
              }

              &:last-child {
                border-bottom: none;
              }
            }
          }
        }
      }

      .li--rside {
        flex: 0 0 40%;
      }
    }
  }

  .dashboard--images-container {
    padding-top: 60px;
    padding-bottom: 60px;

    .di--flex-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 85%;
      margin: auto;

      .di--lside {
        flex: 0 0 60%;
      }

      .di--rside {
        flex: 0 0 35%;

        .di--heading {
          font-size: 28px;
          color: #3e416c;
          text-transform: unset;
        }

        .di--desc {
          font-size: 18px;
          color: #717272;
          font-family: 'Raleway', sans-serif;
          max-width: 90%;
          font-weight: 400;
        }

        .dic--flex-container {
          display: flex;
          margin-top: 20px;
          flex-wrap: wrap;

          .dif--card {
            margin-bottom: 15px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            background-color: #fff;
            border-radius: 10px;
            box-shadow: 0px 3px 9px #e1e1e1;
            border: 2px solid #fff;
            padding: 10px;
            min-width: 400px;
            cursor: pointer;
            position: relative;

            .l--side {
              width: 55px;

              img {
                width: 45px;
                height: 45px;
              }
            }

            .r--side {
              font-size: 18px;
              color: #3e416c;
              font-family: 'Raleway', sans-serif;
              font-weight: 500;
            }

            .check-mark-section {
              img {
                width: 30px;
                height: 30px;
                position: absolute;
                right: 15px;
                top: 18px
              }
            }
          }

          .dif--card.active {
            border-color: #3c9be2; 
          }
        }
      }
    }
  }

  .why-us--conatiner {
    padding: 60px 0;
    background-color: #fafafa;

    .wu--heading {
      font-size: 28px;
      color: #3e416c;
      text-transform: unset;
      text-align: center;
    }

    .wu--desc {
      font-size: 18px;
      color: #717272;
      font-family: 'Raleway', sans-serif;
      font-weight: 400;
      text-align: center;
    }

    .why-us--cards-flex-container {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      max-width: 85%;
      margin: 40px auto 0;

      .wu--card {
        width: calc((100% * 1 / 4.001) - 20px);
        margin: 10px;
        height: 100%;
        background-color: #fff;
        border-radius: 15px;
        padding: 15px;
        box-shadow: 0 3px 9px #e1e1e1;
        min-height: 250px;

        &:hover {
          transform: scale(1.05);
        }

        .image-section {
          margin: 15px 0;
          text-align: center;
          img {
            width: 75px;
            height: 75px;
          }
        }

        .content-section {
          .wu--c-heading {
            text-align: center;
            font-size: 18px;
            margin-bottom: 5px;
          }

          .wu--c-desc {
            font-size: 16px;
            color: #717272;
            font-family: 'Raleway', sans-serif;
            font-weight: 400;
            text-align: center;
          }
        }
      }
    }
  }

  .request-demo--container {
    background-color: #fff;
    padding: 60px 0;

    .request-demo--heading {
      font-size: 28px;
      color: #3e416c;
      text-transform: unset;
    }

    .request-demo--desc {
      font-size: 18px;
      color: #717272;
      font-family: 'Raleway', sans-serif;
      font-weight: 400;
    }

    .request-demo-button,
    .contact-us-button {
      font-family: 'Raleway', sans-serif;
    }
  }

  .footer--container {
    background-color: #3e416c;
    padding: 60px 0;
  }
}

@media screen and (max-width: 767px) {
  .app-home-page--conatiner {
    .landing--section {
      height: auto;
      .ls-flex--section {
        flex-wrap: wrap;
        max-width: 90%;
        padding-top: 120px;

        .ls--lside {
          flex: 0 0 100%;
          text-align: center;
          .app--ladning-heading {
            font-size: 24px;
            margin: auto;
            margin-bottom: 15px;
          }

          .app--landing-desc {
            font-size: 16px;
          }
        }

        .ls--rside {
          flex: 0 0 100%;
          height: auto;
          top: 40px;
          position: relative;

          img {
            max-width: 100%;
          }
        }
      }
    }

    .features--section {
      padding-top: 80px;
      padding-bottom: 40px;

      .fs--flex-container {
        flex-wrap: wrap;
        max-width: 90%;

        .fs--lside {
          flex: 0 0 100%;
          text-align: center;

          .fs--heading {
            font-size: 24px;
          }

          .fs--desc {
            font-size: 16px;
          }
        }

        .fs--rside {
          flex: 0 0 100%;
          .fs--list-flex {
            flex-wrap: wrap;

            .feature--card--info {
              flex: 0 0 100%;
              width: 100%;
              margin: 5px 0;
            }
          }
        }
      }
    }

    .webinar--section {
      .webinar--flex-section {
        height: auto;
        flex-wrap: wrap;
        flex-direction: column-reverse;
        max-width: 90%;
        background: #fff;
        padding: 0;
        box-shadow: none;

        .webinar--lside {
          margin-top: 20px;
          width: 100%;

          .wls--heading {
            font-size: 24px;
          }

          .wls--desc {
            font-size: 16px;
          }
        }

        .webinar--rside {
          img {
            position: unset;
            border-top-left-radius: 15px;
            max-width: 100%;
          }
        }
      }
    }

    .new-way-of-learing--section {
      .nwl--flex-container {
        max-width: 90%;
        flex-wrap: wrap;

        .nsw--lside {
          flex: 0 0 100%;
        }

        .nsw--rside {
          flex: 0 0 100%;

          .nsw--heading {
            font-size: 24px;
          }

          .nsw--desc {
            font-size: 16px;
          }

          .tech--flex-section {
            flex-wrap: wrap;
            .tech--card-section {
              width: 100%;
              margin: 5px 0;
            }
          }
        }
      }
    }

    .language--info-section {
      .li--flex-container {
        max-width: 90%;
        flex-wrap: wrap;
        .li--lside {
          flex: 0 0 100%;

          .li--heading {
            font-size: 24px;
          }

          .li--desc {
            font-size: 16px;
          }

          .li--list-conatiner {
            flex-wrap: wrap;
          }
        }

        .li--rside {
          flex: 0 0 100%;
        }
      }
    }

    .dashboard--images-container {
      .di--flex-container {
        flex-wrap: wrap;
        max-width: 90%;
        flex-direction: column-reverse;

        .di--lside {
        }
        
        .di--rside {
          .di--heading {
            font-size: 24px;
          }

          .di--desc {
            font-size: 16px;
          }
          .dic--flex-container {
            .dif--card {
              min-width: unset;
              width: 100%;
            }
          }
        }
      }
    }

    .why-us--conatiner {
      .wu--heading {
        max-width: 90%;
        margin: auto;
        font-size: 24px;
      }

      .wu--desc {
        max-width: 90%;
        margin: auto;
        font-size: 16px;
      }

      .why-us--cards-flex-container {
        flex-wrap: wrap;
        .wu--card {
          width: 100%;
          margin: 5px 0;
        }
      }
    }

    .request-demo--container {
      padding: 30px 0;

      .request-demo--heading {
        font-size: 24px;
      }
      
      .request-demo--desc {
        font-size: 16px;
      }
    }

    .footer--container {
      padding: 15px;
    }
  }
}