.app-dashboard-details-main-container {
    .meetings-list-container {
        padding: 15px;

        table {
            thead {
                tr.meetings-list-container-header {
                    th {
                        font-size: 12px;
                        background-color: #3e416c;
                        color: #fff;
                        border: none;
                        padding: 8px 15px;
                        &:nth-child(1) {
                            width: 30px;
                            border-top-left-radius: 10px;
                        }
                        &:nth-child(2) {
                            width: 25%;
                        }
                        &:nth-child(3) {
                            width: 85px;
                        }
                        &:nth-child(4) {
                            width: 85px;
                        }
                        &:nth-child(5) {
                            width: 75px;
                        }
                        &:nth-child(6) {
                            width: 20%; 
                        }
                        &:nth-child(7) {
                            width: 15%;
                            text-align: center;
                            border-top-right-radius: 10px;
                        }
                    }
                }
            }

            tr.meetings-list-container-row {
                td {
                    font-size: 12px;
                    color: #414242;
                    font-weight: 600;
                    &:nth-child(1) {
                        width: 30px;
                    }
                    &:nth-child(2) {
                        width: 25%;
                    }
                    &:nth-child(3) {
                        width: 85px;
                    }
                    &:nth-child(4) {
                        width: 85px;
                    }
                    &:nth-child(5) {
                        width: 75px;
                    }
                    &:nth-child(6) {
                        width: 20%; 
                    }
                    &:nth-child(7) {
                        width: 15%;

                        .action-items {
                            text-align: center;

                            button {
                                width: 25px;
                                height: 25px;
                                padding: 0;
                                justify-content: center;
                                display: inline-flex;
                                align-items: center;
                                border-radius: 30px;
                                border: none;
                                &:first-child {
                                    margin-right: 10px;
                                    background-color: #3e416c;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}