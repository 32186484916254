.pdf-reader-container {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  height: 100vh;

  .close-button {
    position: absolute;
    top: 0px;
    right: 0px;

    i {
      color: #fff;
      font-size: 48px;
      cursor: pointer;
    }
  }

  .pdf-reader-overlay {
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    iframe {
      height: 95%;
      width: 95%;
    }
  }
}