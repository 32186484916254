.app-main-features-flex-container {
  display: flex;
  justify-content: space-between;

  .amf-left-side {
    flex: 0 0 70%;
    .amf-left-top-container {
      .amf-left-content {
        font-size: 16px;
        line-height: 32px;
        margin-bottom: 10px;
        font-weight: 500;
        color: rgba(84, 94, 111, .8)
      }
    }
    .amf-left-bottom-container {
      margin-top: 30px;
      .amf-advantages-container {
        margin-top: 20px;

        .col-lg-4 {
          .uk-card-body {
            border: 1px solid #f1f1f1;
          }
        }
      }
    }
  }

  .amf-right-side {
    flex: 0 0 25%;
    .amf-right-heading {
      text-align: center;
    }
    .how-it-works-flex-container {
      justify-content: flex-start;
      align-items: center;
      display: flex;
      box-shadow: 0px 2px 5px #ddd;
      padding: 15px;
      border-radius: 10px;
      margin-bottom: 10px;
      margin-top: 10px;
      box-sizing: border-box;
      border: 1px solid #f1f1f1;
      .hiw-left-side {
        flex: 0 0 70px;
        background-color: #3e416d;
        height: 70px;
        width: 70px;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        display: flex;
        margin-right: 15px;

        h5 {
          color: #fff;
          margin: 0;
          font-size: 24px;
        }
      }

      .hiw-right-side {
        width: calc(100% - 80px);

        .hiw-title {
          font-size: 16px;
          margin: 0 0 5px;
        }

        .hiw-subtitle {
          font-size: 14px;
          line-height: 22px;
          margin: 0;
        }
      }
    }

    .hiw-arrow-container {
      text-align: center;
      margin: 15px 0;

      img {
        max-width: 25px;
      }
    }
  }
}