.login-page-main-container {
  .login-page-layout {
    .login-page-flex-section {
      .right-side-section {
        .image-section-xs {
          display: none;
        }
      }
    }
  }
  .code-data-container {
    margin-top: 35px;
    margin-bottom: 15px;
    .school-code-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #f1f1f1;
      padding: 10px;
      border-radius: 5px;

      p {
        position: relative;
        font-weight: 600;

        span {
          position: absolute;
          top: -30px;
          left: -10px;
          line-height: 15px;
          font-size: 14px;
          border-radius: 5px;
          width: 400px;
        }
      }

      i {
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .login-page-main-container {
    align-items: flex-start;
    min-height: unset;
    .login-page-layout {
      align-items: flex-start;
      
      .login-page-flex-section {
        flex-wrap: wrap;
        .left-side-section {
          display: none;
          flex: 0 0 100%;
          border-radius: 10px;
        }

        .right-side-section {
          flex: 0 0 100%;
          min-height: 100vh;

          .image-section-xs {
            background: #3e416d;
            padding: 10px 15px;
            display: block;
            margin: -20px -20px 0;
          }
        }
      }
    }
  }
}