.app-dashboard-details-main-container {
    .skill-development-list-container {
        padding: 15px;
        .sd-details-flex-container {
            display: flex;
            justify-content: flex-start;
            align-items: stretch;
            flex-wrap: wrap;
            background-color: #fff;
            border: 1px solid #f1f1f1;
            margin-bottom: 20px;
            border-radius: 10px;
            box-shadow: 0.5pt 10px 14px -0.09375in rgba(25,42,70,.13);

            .sd-top-container {
                flex: 0 0 40%;
                padding: 15px;
                .sd-top-thumbnail-inner {
                    background-color: #fff;
                    text-align: center;
                    img, video {
                        border-radius: 10px;
                        max-width: 100%;
                        height: auto;
                    }
                }
            }

            .sd-bottom-container {
                background-color: #fafafa;
                padding: 15px;
                overflow-y: auto;
                flex: 0 0 60%;
                padding: 15px;
                h3 {
                    font-size: 18px;
                    margin: 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .edit-button {
                        background-color: #3e416c;
                        padding: 8px 15px;
                        border-radius: 5px;
                        font-size: 16px;
                        color: #fff;
                        border: none;
                    }
                }

                ul {
                    padding-left: 0px;
                    list-style-type: none;
                    li {
                        font-size: 14px;
                        margin-bottom: 10px;
                        font-weight: 600;
                        color: #414242;
                        display: flex;
                        align-items: center;
                        i {
                            font-size: 24px;
                        }
                    }
                }
            }
        }
    }
}