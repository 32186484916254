.dashboard-main-container {
	padding: 15px;
	.db-top-numbers-list-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		.stat-card-container {
			margin-bottom: 15px;
			width: calc(100% * 1/ 6.0001 - 10px);
			margin: 10px 5px;
			background-color: #fcfcfc;
			border: 1px solid #f1f1f1;
			border-radius: 10px;
			-webkit-box-shadow: 0.5pt 10px 14px -0.09375in rgba(25,42,70,.13);
			box-shadow: 0.5pt 10px 14px -0.09375in rgba(25,42,70,.13);
			padding: 10px;
			box-sizing: border-box;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			&:hover {
				transform: translate(0,-4px);
			}

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}
}
.db-top-numbers-list-container {
	.stat-card-container {
		.stat-left-side-section {
			width: 50px;
			margin-right: 10px;
			background-color: #f1f1f1;
			border-radius: 50%;
			min-height: 50px;
			box-shadow: 0 2px 4px #e1e1e1;
			display: flex;
			justify-content: center;
			align-items: center;
			svg {
				width: 25px;
				height: 25px;
			}
		}
		.stat-right-side-section {
			width: calc(100% - 80px);
			h5 {
				margin: 0;
				font-size: 14px;
				font-weight: 600;
			}
			h3 {
				margin: 0;
				font-size: 20px;
			}
		}
	}
}
.recharts-default-legend {
	text-align: center !important;
	.recharts-legend-item-text {
		font-size: 12px;
		font-weight: 600;
	}
}
.dashboard-flex-container {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	.db-right-side-section {
		width: calc(100% - 715px);
	}
}
.db-left-side-section {
	width: 700px;
	.db-left-flex-container {
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
	}
}
.db-left-flex-container {
	.db-attendance-percentange-graph {
		background-color: #fff;
		box-shadow: 0.5pt 10px 14px -0.09375in rgba(25,42,70,.13);
		max-width: 440px;
		padding: 20px;
		box-sizing: border-box;
		border: 1px solid #f1f1f1;
		border-radius: 10px;
		h4 {
			font-size: 16px;
		}
	}
	.db-performance-percentange-graph {
		background-color: #fff;
		box-shadow: 0.5pt 10px 14px -0.09375in rgba(25,42,70,.13);
		max-width: 440px;
		padding: 20px;
		box-sizing: border-box;
		border: 1px solid #f1f1f1;
		border-radius: 10px;
		margin-left: 15px;
		h4 {
			font-size: 16px;
		}
	}

	.leave-request-container {
		margin-top: 15px;
		background-color: #fff;
		box-shadow: 0.5pt 10px 14px -0.09375in rgba(25,42,70,.13);
		width: 700px;
		padding: 20px;
		box-sizing: border-box;
		border: 1px solid #f1f1f1;
		border-radius: 10px;
		height: 300px;
		overflow-y: auto;
		h4 {
			font-size: 16px;
		}

		.leave-request-list {
			.leave-request-row {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				padding-bottom: 10px;
				padding-top: 10px;
				border-bottom: 1px solid #fafafa;

				&:last-child {
					border-bottom: none;
				}
				.xs-sec {
					max-width: 40px;
					margin-right: 10px;
					.l-r-image {
						max-width: 40px;
						max-height: 40px;
						border-radius: 30px;
					}
				}
				
				.small-sec {
					width: 100px;
					margin-right: 10px;
					.l-r-date {
						font-size: 12px;
						margin: 0;
						text-align: center;
					}
				}

				.big-sec {
					margin-right: 10px;
					width: 125px;
					.l-r-name {
						font-size: 13px;
						margin: 0;
					}
				}

				.large-sec {
					text-align: right;
					width: 300px;
					.l-r-approve {
						margin-right: 10px;
						border: none;
						background-color: #36b37e;
						color: #fff;
						font-size: 10px;
						border-radius: 30px;
						height: 25px;
						display: inline-flex;
						justify-content: center;
						align-items: center;
						padding: 0 10px;
						font-weight: 600;
						font-family: 'gotham-rounded', 'helvetica neue' ,helvetica;
						i {
							padding-left: 5px;
						}
					}

					.l-r-reject {
						margin-right: 10px;
						border: none;
						background-color: #3e416c;
						color: #fff;
						font-size: 10px;
						border-radius: 30px;
						height: 25px;
						display: inline-flex;
						justify-content: center;
						align-items: center;
						padding: 0 10px;
						font-weight: 600;
						font-family: 'gotham-rounded', 'helvetica neue' ,helvetica;
						i {
							padding-left: 5px;
						}
					}
				}
			}
		}
	}
}
.db-upcoming-events-container {
	height: 280px;
	background-color: #fff;
	box-shadow: 0.5pt 10px 14px -0.09375in rgba(25,42,70,.13);
	width: 100%;
	box-sizing: border-box;
	border: 1px solid #f1f1f1;
	border-radius: 10px;
	h4 {
		font-size: 16px;
		padding: 20px 20px 0;
		box-sizing: border-box;
		margin-bottom: 0;
	}
}

.db-upcoming-birthdays-container {
	margin-top: 15px;
	width: 100%;
	background-color: #fff;
	box-shadow: 0.5pt 10px 14px -0.09375in rgba(25,42,70,.13);
	box-sizing: border-box;
	border: 1px solid #f1f1f1;
	border-radius: 10px;
	height: 300px;
	h4 {
		font-size: 16px;
		padding: 20px 20px 0;
		box-sizing: border-box;
		margin-bottom: 0;
	}
}
.meetings-list-main-container {
	overflow-y: auto;
	height: calc(100% - 45px);
	.meeting-list-flex-section {
		display: flex;
		-ms-flex-pack: start;
		justify-content: flex-start;
		-ms-flex-align: center;
		align-items: center;
		border-bottom: 2px solid #f1f1f1;
		margin: 10px;
		padding: 5px 15px 10px;
		position: relative;

		&:last-child {
			border-bottom: none;
		}
		.meeting-left-side {
			width: 60px;
			margin-right: 10px;
			.meeting-top-container {
				background: #3e416c;
				text-align: center;
				border-top-left-radius: 8px;
				border-top-right-radius: 8px;
				height: 20px;
				justify-content: center;
				align-items: center;
				display: flex;
				.meeting-top-month-name {
					font-size: 10px;
					color: #fff;
					font-weight: 600;
				}
			}
			.meeting-bottom-container {
				text-align: center;
				border: 1px solid #ddd;
				border-bottom-left-radius: 8px;
				border-bottom-right-radius: 8px;
				height: 30px;
				justify-content: center;
				align-items: center;
				display: flex;
				.meeting-bottom-date-name {
					font-size: 12px;
					font-weight: 600;
				}
			}
		
			.user-img {
				max-width: 55px;
				border-radius: 50%;
			}
		}
		.meeting-right-side {
			width: calc(100% - 70px);
			h5 {
				font-size: 14px;
				margin: 0;
			}
			p {
				font-size: 11px;
				margin: 0;
				font-weight: 600;
				color: #3c9be2;
			}
		}

		.cancel-meeting-container {
			position: absolute;
			right: 10px;
			top: 10px;

			i {
				color: #ad4a3d;
			}
		}
	}
}

@media screen and (max-width: 1024px) and (min-width: 767px) {
	.app-dashboard-main-container {
		.dashboard-main-container {
			padding: 20px 0;
			.db-top-numbers-list-container {
				.stat-card-container {
					width: calc(100% * 1/ 3.0001 - 10px);
					margin: 5px;
				}
			}

			.dashboard-flex-container {
				margin: 5px;
				flex-wrap: wrap;
				.db-left-side-section {
					flex: 0 0 100%;
					.db-left-flex-container {
						flex: 0 0 100%;
						justify-content: space-between;

						.db-attendance-percentange-graph {
							flex: 0 0 49%;
							margin: 0;
						}

						.db-performance-percentange-graph {
							flex: 0 0 49%;
							margin: 0;
						}

						.leave-request-container {
							flex: 0 0 100%;
							margin-bottom: 15px;
						}
					}
				}
				.db-right-side-section {
					flex: 0 0 100%;
				}
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.app-dashboard-main-container {
		.dashboard-main-container {
			padding: 20px 0;
			.db-top-numbers-list-container {
				.stat-card-container {
					width: calc(100% * 1/ 2.0001 - 10px);
					margin: 5px;
				}
			}

			.dashboard-flex-container {
				margin: 5px;
				flex-wrap: wrap;
				.db-left-side-section {
					flex: 0 0 100%;
					.db-left-flex-container {
						flex: 0 0 100%;
						justify-content: space-between;

						.db-attendance-percentange-graph {
							flex: 0 0 100%;
							margin: 0;
						}

						.db-performance-percentange-graph {
							flex: 0 0 100%;
							margin: 0;
						}

						.leave-request-container {
							flex: 0 0 100%;
							margin-bottom: 15px;
						}
					}
				}
				.db-right-side-section {
					flex: 0 0 100%;
				}
			}
		}
	}
}