.app-dashboard-details-main-container {
    .user-profile-change-form-container {
        padding: 15px;
        .uk-text-bold {
            font-size: 14px;
        }

        .change-password-form-container {
            align-items: center;
        }
    }
}