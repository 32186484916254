.app-dashboard-details-main-container {
    .student-performance-details-container {
        padding: 15px;
        .top-container {
            margin-bottom: 15px;
            .flex-section {
                display: flex;
                justify-content: flex-start;
                align-items: stretch;
                .image-section {
                    width: 100px;
                    height: 100px;
                    margin-right: 20px;
                    background-color: #3e416c;
                    border-radius: 50%;
                    img {
                        max-width: 100%;
                        border-radius: 50%;
                        height: auto;
                    }
                }

                .details-section {
                    width: 25%;
                    margin-right: 20px;
                    h5 {
                        margin: 0;
                    }

                    p {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        font-size: 12px;
                        margin-bottom: 5px;
                        .heading-text {
                            font-weight: 600;
                            min-width: 80px;
                            display: inline-block;
                        }
                    }
                }

                .overall-grade-section {
                    width: 100px;
                    margin-right: 20px;

                    h5 {
                        font-size: 14px;
                        margin: 0 0 5px;
                    }
                    .grade-container {
                        height: 80px;
                        width: 80px;
                        margin: auto;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: #ddd;

                        p {
                            margin: 0;
                            color: #3e416c;
                            font-size: 48px;
                            font-weight: 600;
                        }
                    }
                }

                .tests-list-section {
                    .test-name {
                        font-size: 14px;
                        font-weight: 600;
                        color: #3e416c;
                        width: 100%;
                    }
                    div {
                        padding: 0px 5px;
                        float: left;
                    }
                }
            }
        }

        .bottom-container {
            margin-top: 40px;
            .subject-wise-performance-container {
                .header-section {

                }

                .body-section {
                    table {
                        tr.subject-wise-table-header {
                            th {
                                background-color: #3e416c;
                                padding: 8px;
                                color: #fff;
                                font-size: 14px;

                                &:first-child {
                                    border-top-left-radius: 10px;
                                }

                                &:last-child {
                                    border-top-right-radius: 10px;
                                }
                            }
                        }

                        tr.subject-wise-table-row {
                            td {
                                font-size: 12px;
                                color: #414242;
                                font-weight: 600;

                                &:first-child {
                                    border-top-left-radius: 10px;
                                }

                                &:last-child {
                                    border-top-right-radius: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}