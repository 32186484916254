.dashboard-main-container {
    .childrens-details-flex-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .db-p-details-main-section {
            flex: 0 0 49%;
            background-color: #fff;
            box-shadow: 0.5pt 10px 14px -0.09375in rgba(25,42,70,.13);
            padding: 20px;
            box-sizing: border-box;
            border: 1px solid #f1f1f1;
            border-radius: 10px;
            margin-bottom: 15px;

            h4 {
                font-size: 16px;
            }

            .children-details-top-container {
                display: flex;
                justify-content: space-between;
                .children-left-side-section {
                    flex: 0 0 35%;
                    text-align: center;
                    .db-p-student-profile {
                        width: 80px;
                        height: 80px;
                        background-color: #f1f1f1;
                        border-radius: 50%;
                        justify-content: center;
                        align-items: center;
                        display: flex;
                        margin: auto;
    
                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                        }
                    }
    
                    h4 {
                        margin: 10px 0 0;
                        font-size: 16px;
                    }
    
                    p {
                        margin: 0;
                        font-size: 14px;
                        font-weight: 600;
                    }

                    .db-p-class-teacher-details {
                        margin-top: 10px;
                        padding: 10px 10px 10px;
                        box-sizing: border-box;
                        background: #fafafa;
                        border-radius: 5px;
                        position: relative;
                        text-align: left;
                        border: 1px solid #f1f1f1;

                        p {
                            font-size: 12px;
                        }

                        .class-teacher {
                            font-size: 10px;
                            top: 2px;
                        }
                    }
                }

                .children-right-side-section {
                    flex: 0 0 60%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;
                    .stat-card-container {
                        margin-bottom: 15px;
                        width: 48%;
                        background-color: #fcfcfc;
                        border: 1px solid #f1f1f1;
                        border-radius: 10px;
                        margin-right: 0.667%;
                        -webkit-box-shadow: 0.5pt 10px 14px -0.09375in rgba(25,42,70,.13);
                        box-shadow: 0.5pt 10px 14px -0.09375in rgba(25,42,70,.13);
                        padding: 10px 15px;
                        box-sizing: border-box;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        &:hover {
                            transform: translate(0,-4px);
                        }

                        .stat-left-side-section {
                            width: 50px;
                            background-color: #f1f1f1;
                            border-radius: 50%;
                            min-height: 50px;
                            box-shadow: 0 2px 4px #e1e1e1;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            svg {
                                width: 25px;
                                height: 25px;
                            }
                        }
                        .stat-right-side-section {
                            text-align: center;
                            margin-top: 5px;
                            h5 {
                                margin: 0;
                                font-size: 14px;
                                font-weight: 400;
                            }
                            h3 {
                                margin: 0;
                                font-size: 20px;
                            }
                        }
                    }
                }
            }

            .children-details-bottom-container {
                border-top: 2px solid #fafafa;
                padding-top: 15px;
                margin-top: 15px;
                h4 {
                    font-size: 16px;
                }
            }
        }
    }
}