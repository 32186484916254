.d-app-subject-list-container {
    padding: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    .subject-details-flex-card {
        width: calc(100% * 1 / 3.001 - 20px);
        margin: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;

        .subjct-card {
            padding: 15px;
            border-radius: 10px;
            width: 100%;

            p.uk-h4 {
                margin-bottom: 0px;
                font-size: 18px;
            }

            p {
                font-weight: 600;
                margin: 0;
                margin: 0;
                line-height: 20px;
                
                a {
                    display: inline-block;
                    font-size: 10px;
                    color: #3e416c;
                }
            }

            .edit-button {
                background-color: #3e416c;
                border-color: #3e416c;
            }
        }

        .go-to-button-section {
            width: 80px;
            text-align: center;

            a {
                display: flex;
                justify-content: center;
                align-items: center;                   
            }
            button {
                outline: none;
                cursor: pointer;
                height: 50px;
                width: 50px;
                border: none;
                border-radius: 50%;
                font-size: 24px;
                color: #3e416c;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .d-app-subject-list-container {
        padding: 15px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
    
        .subject-details-flex-card {
            width: 100%;
        }
    } 
}