body {
  background-color: #edeff0 !important;
}
.page-content-inner{
  padding-top: 0;
}
.demo-hero {
  background: linear-gradient(50deg, #273444, #272b44) !important;
}

.demo-card {
  text-align: center;
  color: #4a4a4a;
  text-transform: capitalize;
}

.demo-card img {
  border-radius: 8px;
  box-shadow: 0px 2px 14px -3px #00000026;
}

.demo-card:hover.demo-card img {
  transition: 0.4s ease all;
  transform: translateY(-10px);
  box-shadow: rgba(34, 48, 102, 0.1) 0px 18px 27.2px -5px;
}

.demo-card p {
  margin-top: 15px;
}

.my-list {
  margin: 0;
  padding: 0;
}

.my-list li {
  list-style: none;
  display: inline-block;
  padding: 8px 23px;
  background: #f4fff7f0;
  margin-bottom: 8px;
  margin-left: 5px;
  border-radius: 100px;
}

.my-list li:hover {
  background: #f4fff7;
  transition: 0.4s ease all;
  transform: translateY(-2px);
}

.my-list li a {
  color: #7b7b7b;
}

.my-list li a:hover {
  color: #132763;
}

.white-font {
  color: white !important;
}

.main-header {
  z-index: 1000 !important;
  position: relative;
  background: white;
  box-shadow: 0 1px 10px rgba(151, 164, 175, 0.1);
}

.header-transparent {
  background: transparent !important;
  box-shadow: none !important;
  position: absolute;
  width: 100%;
}

.main-header #logo {
  font-size: 21px;
  font-weight: 600;
}

.main-header #logo i {
  font-size: 28px;
}

.uk-navbar-nav>li>a {
  font-weight: 600;
  font-size: 15.6px;
  padding: 1.5rem 1.1rem;
  text-transform: inherit;
}

.demo-nav>li>a:after {
  background-color: rgba(62, 65, 109, 0.57);
  border-radius: 4px;
  content: "";
  margin-top: 8px;
  height: 12px;
  position: absolute;
  transform: scaleX(0);
  transition: transform 0.25s;
  width: 60px;
}

.demo-nav>.uk-active a:after {
  transform: scaleX(1);
}

.demo-cards .uk-card-default{
border-radius: 7px !important;
}    
.buttons .btn {
border-radius: 0;
width: 10;
}

.buttons .btn-default  {
color: #3e416d;
border-color: #f1f1f1;
background-color: #f1f1f1;
}

.buttons .btn-default:hover {
color: #fff;
border-color: #484c7d;
background-color: #484c7d;
}

.buttons .btn:first-child {
border-left: none;
margin-left: 0 !important;
border-top-left-radius: .28571429rem;
border-bottom-left-radius: .28571429rem;
}

.buttons .btn:last-child {
border-top-right-radius: .28571429rem;
border-bottom-right-radius: .28571429rem;
margin-left: -5px !important;
}

.demo-new-badge {
  background: #ff7676;
  position: absolute;
  right: -15px;
  color: white;
  padding: 3px 10px;
  font-size: 15px;
  box-shadow: 1px 1px 10px -4px black;
  border-radius: 4px;
  top: -10px;
}

.preview-btn:hover {
  color: white;
  background: #515c65;
}

.preview-btn {
  background: #3e474f;
  padding: 0px 22px;
  color: white;
}

.hero-slider {
  background-image: url("/assets/images/demos/home-labtop.png");
  background-position: bottom;
  padding-top: 0;
  background-size: contain;
  background-repeat: no-repeat;
  height: 75vh;
  margin-top: 5%;
}

.full-height {
  min-height: 100vh;
}

.hero {
  display: inline-block;
  width: 100%;
  position: relative;
  background: white;
}

.diagonal-hero-bg {
  top: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(to right, #2b5876, #2f2a44);
  background: -webkit-linear-gradient(to right, #2b5876, #2f2a44);
  z-index: -1;
}

.diagonal-hero-innr {
  padding-left: 10%;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("/assets/images/demos/bg-pricing-page.jpg");
}

.stars {
  z-index: -1;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transition: opacity 1s ease-in-out;
  box-sizing: border-box;
}

.stars>.small {
  width: 1px;
  height: 1px;
  background: transparent;
  animation: starsAnimation 50s linear infinite;
}

.stars>.small:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 1px;
  height: 1px;
  background: transparent;
  
}

.stars>.medium {
  width: 2px;
  height: 2px;
  background: transparent;
  animation: starsAnimation 100s linear infinite;
}

.stars>.medium:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 2px;
  height: 2px;
  background: transparent;
}

.stars>.big {
  width: 3px;
  height: 3px;
  background: transparent;
  border-radius: 100%;
  animation: starsAnimation 150s linear infinite;
}

.stars>.big:after {
  content: " ";
  position: absolute;
  top: 2000px;
  width: 3px;
  height: 3px;
  background: transparent;
  border-radius: 100%;
}

@keyframes starsAnimation {
  from {
      transform: translateY(-2000px);
  }

  to {
      transform: translateY(0px);
  }
}
.srv-validation-message {
  color: red !important;
  padding-top: 0px !important;
  margin-top: 0px !important;
  font-size: 11px;
}

.btn {
  cursor: pointer;
}

.btn-xs {
  padding: .5rem 0.5rem !important;
  font-size: .7rem !important;
  line-height: 0.6 !important;
  border-radius: .10rem;
}

.btn-xxs {
  padding: .3rem 0.3rem !important;
  font-size: .6rem !important;
  line-height: 0.5 !important;
  border-radius: .8rem;
}

.top-right {
  position: absolute;
  top: 2px;
  right: 2px;
}

.danger {
  color: red !important;
}

.border-round{
  border: 1px solid light gray;
}

.pointer {
  cursor: pointer !important;
}

@media only screen and (max-width: 600px) {
  /* .home {
    padding-top: 100px;
  } */

  .home-page {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .home-header {
    padding-top: 50px;
    font-size: 16px;
  }

  .page-content-inner {
    height: 75px;
  }
}

.bold {
  font-weight: bold;
}

.float-left {
  float: left;
}

a.green-font {
  background-color: white !important;
  padding: 0px !important;
  margin: 0px !important;
  color: green !important;
}


.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.text-center {
  text-align: center;
}

footer {
  background: #efe9fb;
  margin: 0px 0px 0px 0px;
  position: fixed;
  bottom: 0 !important;
  width: 100%;
  height: 50px;
  line-height: 50px;
}

p{
  margin-bottom: 0px;
}

.rounded-circle {
  border: 1px solid #e0e0e0;

}

.login-page-main-container {
  // background-color: #3e416d;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}



.login-page-layout {
  max-width: 1000px;
  margin: auto;
  min-height: 650px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-page-flex-section {
  background-color: #fff;
  width: 100%;
  height: 100%;
  min-height: 500px;
  border-radius: 10px;
  box-shadow: 0px 2px 6px #ddd;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.login-page-flex-section .left-side-section {
  flex: 0 0 50%;
  padding: 40px;
  box-sizing: border-box;
  border-right: 1px solid #f1f1f1;
  align-items: flex-start;
  background-color: #3e416d;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  .app-details-section {
    text-align: center;
  }
  .extra-info-container {
    padding-top: 40px;
    h4 {
      color: #f1f1f1;
      font-size: 24px;
      text-transform: unset;
      text-align: center;
    }

    p {
      color: #e1e1e1;
      font-size: 16px;
      line-height: 28px;
    }

    ul {
      margin-top: 25px;
      list-style-type: none;
      padding-left: 0;
      li {
        margin-bottom: 10px;
        font-size: 16px;
        color: #ddd;
        font-weight: 600;
      }
    }
  }
}

.login-page-flex-section .right-side-section {
  flex: 0 0 50%;
  padding: 40px;
  box-sizing: border-box;
  margin-left: 0;
}

.login-page-flex-section .right-side-section .app-details-section a {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.login-page-flex-section .right-side-section .app-details-section a i {
  font-size: 44px;
  color: #3e416d;
  margin-right: 5px;
}

.login-page-flex-section .right-side-section .app-details-section a .app-title {
  font-size: 28px;
  color: #3e416d;
  font-weight: bold;
  margin-top: 2px;
}

.login-page-flex-section .right-side-section .login-tab-flex-section {
  display: flex;
  justify-content: center;
  align-self: center;
  margin-top: 20px;
}

.login-page-flex-section .right-side-section .login-tab-flex-section .student-section,
.login-page-flex-section .right-side-section .login-tab-flex-section .parent-section {
  width: 150px;
  height: 60px;
  border: 2px solid #f1f1f1;
  border-radius: 5px;
  padding: 5px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  background-color: #fafafa;
}

.login-page-flex-section .right-side-section .login-tab-flex-section .student-section {
  margin-right: 20px;
}

.login-page-flex-section .login-tab-flex-section .student-section img {
  max-width: 30px;
  max-height: 30px;
  margin-right: 10px;
}

.login-page-flex-section .login-tab-flex-section .parent-section img {
  max-width: 40px;
  max-height: 40px;
  margin-right: 10px;
}

.login-tab-heading {
  font-size: 16px;
  color: #333;
  margin-bottom: 0;
}

.login-page-flex-section .right-side-section .login-tab-flex-section .student-section.active-tab,
.login-page-flex-section .right-side-section .login-tab-flex-section .parent-section.active-tab {
  border: 2px solid #3e416d;
  background-color: #3e416d;
}

.login-tab-flex-section .parent-section.active-tab .login-tab-heading,
.login-tab-flex-section .student-section.active-tab .login-tab-heading {
  color: #ffff;
}

.login-page-flex-section .right-side-section .login-form-container-main {
  // margin-top: 20px;
}

.login-page-flex-section .right-side-section .login-form-container-main .login-button{
  margin-top: 20px;
  width: 100%;
  height: 50px;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.login-page-flex-section .faculty-login-link-section {
  margin-top: 15px;
  text-align: center;
}

.login-page-flex-section .faculty-login-link-section a {
  color: #3e416d;
  font-size: 16px;
  font-weight: 600;
}


@media screen and (max-width: 767px) {

  .login-page-main-container {
    min-height: unset;
  }

  .login-page-layout {
    height: unset;
  }

  .login-page-flex-section {
    background-color: #fff;
    width: 100%;
    height: 100%;
    height: auto;
    border-radius: 0px;
    box-shadow: 0px 2px 6px #1d1f34;
    display: block;
    justify-content: space-between;
    align-items: stretch;
  }

  .login-page-flex-section .left-side-section {
    flex: 0 0 50%;
    padding: 20px;
    box-sizing: border-box;
    border-right: 1px solid #f1f1f1;
  }

  .login-page-flex-section .right-side-section {
    padding: 20px;
    margin-top: 0;
  }

  .login-page-flex-section .left-side-section .login-illustration-image {
    max-height: 300px;
  }
}

.text-center {
  text-align: center;
}

.subject-cover {
  width: 100%;
  border-radius: 15px 15px 0px 0px;
}

.np {
  padding:0px;
  margin:0px;
}
.subject-box .card{
  border-radius: 15px;
}

.calendar .rbc-toolbar > span:first-child {
  display: none !important;
}

.pb-0 {
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}
.date {
  font-weight: bold;
}

.underline {
  border-bottom: 2px solid lightgray;
}

.text-center {
  text-align: center !important;
}

.red-text {
  color: red !important;
}

.branch-logo {
  max-width: 60px;
}
.p-15 {
  padding: 15px !important;
}
.border-15 {
  border-radius: 15px !important;
}
input[type=checkbox] {
  text-align: center !important;
  height: 25px !important;
  width: 25px !important;
}
#input-file, #input-logo {
  display: none;
}

.text-center {
  text-align: center;
}

.subject-cover {
  width: 100%;
  border-radius: 15px 15px 0px 0px;
}

.np {
  padding:0px;
  margin:0px;
}
.subject-box .card{
  border-radius: 15px;
}

.t-0, .b-0 {
  padding-top: 1px;
  margin-top: 0px;
  padding-bottom: 1px;
  margin-bottom: 0px;
}

.form-control-position {
  right: 10px !important;
}

.text-center {
  text-align: center;
}

.subject-cover {
  width: 100%;
  border-radius: 15px 15px 0px 0px;
}

.np {
  padding:0px;
  margin:0px;
}
.subject-box .card{
  border-radius: 15px;
}
.p1{
  padding: 10px;
  border-radius: 10px;
}
.hover-item:hover{
  background-color: aliceblue;
}
.round-img {
  width: 100%;
}

.thumbnail-img {
  max-height: 100px;
  width: 100% !important;
}

[class~=topic-1] {
  border-top: 1px solid white;
  background-color: #3e416d !important;
}

.text-center {
  text-align: center;
}

.subject-cover {
  width: 100%;
  border-radius: 15px 15px 0px 0px;
}

.np {
  padding:0px;
  margin:0px;
}
.subject-box .card{
  border-radius: 15px;
}
.p1{
  padding: 10px;
  border-radius: 10px;
}
.hover-item:hover{
  background-color: aliceblue !important;
}

.text-10 {
  font-size: 15px;
}

.max-100 {
  max-width: 150px !important;
}

.font-black {
  color: black;
}

.text-center {
  text-align: center;
}

.subject-cover {
  width: 100%;
  border-radius: 15px 15px 0px 0px;
}

.np {
  padding:0px;
  margin:0px;
}
.subject-box .card{
  border-radius: 15px;
}
.subject-box .card{
  border-radius: 15px;
}

.overall-grade {
  font-size: 70px;
  margin-right: 30px;
}

label.text-center {
  display: block;
  text-align: center;
}
.mw-30  {
  min-width: 30px !important;
}

.text-center {
  text-align: center;
}

.subject-cover {
  width: 100%;
  border-radius: 15px 15px 0px 0px;
}

.np {
  padding:0px;
  margin:0px;
}
.subject-box .card{
  border-radius: 15px;
}

.scrollable {
  max-width: 100%;
  overflow-x: auto;
}

.min-100 {
  min-width: 150px;
}

.border-right {
  border-right: 1px solid lightgray;
}

.small-text ul li{
    font-size: 10px;
}

.float-right {
    text-align: right !important;
}

.no-style {
    list-style: none;
}

.mh-100 {
    max-height: 100px !important;
}

.max-100 {
    max-width: 100% !important;
}
.message-content-inner {
    max-height: 370px !important;
    overflow-y: auto !important;
}

.tiny-text {
    font-size: 10px !important;
}

.text-center {
  text-align: center;
}

.subject-cover {
  width: 100%;
  border-radius: 15px 15px 0px 0px;
}

.np {
  padding:0px;
  margin:0px;
}
.subject-box .card{
  border-radius: 15px;
}

.h350 {
  min-height: 350px;
}
.border1 {
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-right: 10px;
}
.padding-top-1 {
  padding-top: 1px;
}

.similar-videos {
  max-height: 413px;
  overflow-y: auto;
}

.pt--15 {
  margin-top: -25px;
}

.no-top {
  padding-top:0px;
  margin-top:0px;
}

.p5 {
  padding: 5px;
  margin: 5px;
}

video::-internal-media-controls-download-button {
  display:none;
}
video::-webkit-media-controls-enclosure {
  overflow:hidden;
}
video::-webkit-media-controls-panel {
  width: calc(100% + 30px); /* Adjust as needed */
}

.text-center {
    text-align: center;
}

.subject-cover {
    width: 100%;
    border-radius: 15px 15px 0px 0px;
}

.np {
    padding:0px;
    margin:0px;
}
.subject-box .card{
    border-radius: 15px;
}

.modal-xl {
    min-width: 90% !important;
}

.blue-font {
    color: blue;
}

.library-book {
    padding: 5px;
    background-color: white;
}

.library-book i {
    padding-top: 3px;
}

html body.bg-full-screen-image {
  background-color: #F2F4F4 !important;
}

.p-20 {
  padding: 20px;
}

.bg-transparent {
  background-color: transparent;
}

.text-20 {
  font-size: 20px !important;
}

.searchBox {
  border: 1px solid #cccccc !important;
}

._2OR24XnUXt8OCrysr3G0XI {
  border: 0px;
}

.cal-box {
  text-align: center;
  
  border-radius: 7px;
}
.cal-month {
  text-transform: capitalize;
  padding: 10px;
  font-size: 15px;
  font-weight: bold;
  color: white;
  background-color: #00b8d9;
  border-radius: 5px 5px 0px 0px;
}

.cal-date {
  min-width: 76px;
  color:  #00b8d9;
  font-weight: bold;
  border-right: 1px solid #00b8d9;
  border-bottom: 1px solid #00b8d9;
  border-left: 1px solid #00b8d9;
  border-radius: 0px 0px 5px 5px;
}

.width-75 {
  width: 75px !important;
}

.less-padding td {
  padding: 0.5rem 1rem !important;
}

.p10 {
  margin: 10px 0px !important;
}
.marks-td {
  margin: 5px;
  background-color: lightgray;
  padding:5px;
}

.nowrap {
  display: nowrap;
  max-width: 1000px;
  overflow-x: auto;
}

.marks-btn {
  min-width: 70px;
}

.point-btn {
  min-width: 30px;
}

.classes-td {
  min-width: 100px;
}

.grades-system table {
  border-collapse: separate;
  border-spacing: 0;
}

.grades-system td, .grades-system th {
  margin: 5px;
  white-space: nowrap;
  border-top-width: 0px;
}

.grades-system {
  width: 860px;
  overflow-x: scroll;
  margin-left: 70px;
  overflow-y: visible;
  padding: 0;
}

.grades-system .headcol {
  position: absolute;
  width: 5.0em;
  left: 30px;
  top: auto;
  border-top-width: 1px;
  /*only relevant for first row*/
  margin-top: -1px;
  /*compensate for top border*/
}

.grades-system .long {
  letter-spacing: 0.5em;
}

.text-center {
  text-align: center;
}

.subject-cover {
  width: 100%;
  border-radius: 15px 15px 0px 0px;
}

.np {
  padding:0px;
  margin:0px;
}
.subject-box .card{
  border-radius: 15px;
}

.h350 {
  min-height: 350px;
}

.round-btn {
  border-radius: 50%;
  margin-left: 10px;
  width: 30px;
  margin-top: -5px;
}

.videos-container {
  min-height: 300px;
}

#self-video {
  width: 100%;
}

.chat-switcher li{
  padding-top: 10px;
  font-size: 15px;
}

.large-icon {
  font-size: 20px;
}

.live-video {
  background-color: transparent;
}

.action-icon {
  font-size: 18px;
}

.pr-0 {
  padding-right: 0px !important;
  margin-right: 0px !important;
}

.hidden {
  display: none;
}

.chat-input-box {
  height: 30px !important;
  padding: 5px 10px !important;
}

#remote-videos .media-box video {
  width: 50% !important;
}
.width-100 {
  width: 100% !important;
}

.small-text {
  font-size: 12px;
}

.live-class .section-header {
  padding-bottom: 5px;
  margin-bottom: 5px;
}
.item-tag {
  margin-left: 70% !important;
}
.text-12 {
  font-size: 12px;
}
.black-font {
  color: black;
}

.text-center {
  text-align: center;
}

.np {
  padding:0px;
  margin:0px;
}
.subject-box .card{
  border-radius: 15px;
}

.table th {
  padding: 1.15rem 1rem;
}

input[type=checkbox] {
  height: 25px !important;
  width: 25px !important;
}

.volume-control {
  margin-top: 0px !important;
}

.text-center {
  text-align: center;
}

.np {
  padding:0px;
  margin:0px;
}
.subject-box .card{
  border-radius: 15px;
}

.table th {
  padding: 1.15rem 1rem;
}

input[type=checkbox] {
  height: 25px !important;
  width: 25px !important;
}

.volume-control {
  margin-top: 0px !important;
}

.width-100 {
  width: 100% !important;
}

.small-text {
  font-size: 12px;
}

.live-class .section-header {
  padding-bottom: 5px;
  margin-bottom: 5px;
}
.item-tag {
  margin-left: 70% !important;
}

h2.swal2-title {
  font-size: 18px !important;
}

.swal2-content {
  font-size: 15px !important;
}
.swal2-modal {
  max-width: 300px !important;
  border-radius: 0px !important;
}

.swal2-actions button {
  padding: 0.2rem 1rem !important;
  font-size: 14px !important;
}


.np {
  padding:0px;
  margin:0px;
}
.subject-box .card{
  border-radius: 15px;
}

.mh-150 {
  min-height: 300px !important;
}

.round-img img{
  max-width: 30px;
}

.dummy-card {
  background-color: white !important;
  padding: 10px;
}

.skill-proceedure {
  max-height: 300px;
  overflow: auto;
}


.text-20 {
  font-size: 20px !important;
}

.m20 {
  margin: 20px;
}

.question-area {
  background-color: lightgray;
}

.red-text{
  color: red;
}

.green-text{
  color: green;
}
.width-100 .collapse-header {
  width: 100%;
}
.border-1 {
  border: 1px solid lightgray;
}
.submissions .box {
  max-height: 60px !important;
  max-width: 150px !important;
}
.submissions .uk-countdown-number {
  font-size: 20px !important;
  padding: 5px !important;
}

.submissions .countdown-text {
  padding-top: 2px !important;
  max-height: 20px !important;
}

.border-1 {
  border: 1px solid lightgray;
}
.submissions .box {
  max-height: 60px !important;
  max-width: 150px !important;
}
.submissions .uk-countdown-number {
  font-size: 20px !important;
  padding: 5px !important;
}

.submissions .countdown-text {
  padding-top: 2px !important;
  max-height: 20px !important;
}

ul{
  text-align: left !important;
}

.uk-slider-items li {
  width: 100%;
}

.parent-image {
  max-width: 100px;
}

.parent-image img{
  max-width: 100px;
}

.parent-details{
  min-width: 359px;
  margin-left: 10px;
  min-height: 120px;
}

.rounded-border {
  border-radius: 15px;
}

.student-profile img{
  max-width: 100px;
  max-height: 70px;
  border-radius: 50%;
}

.max-50 {
  padding-top: 10px;
  padding-bottom: 10px;
  max-width: 30px;
}

.how-it-works-container {
  justify-content: flex-start;
  align-items: center;
  display: flex;
  box-shadow: 0px 2px 5px #ddd;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  box-sizing: border-box;
}

.how-it-works {
  flex: 0 0 70px;
  background-color: #3e416d;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-right: 15px;
}

.how-it-works-content-section {
  text-align: left;
}
.top-50 {
  padding-top: 15px;
}

.max-50 {
  max-width: 40px;
}

.small-img {
  max-width: 30px;
  margin-right: 15px;
}

.border-1 {
border: 1px solid lightgray;
}

.about-us .blog-article-single {
  max-height: 250px;
  border-radius: 0px;
}

.font-50 {
  font-size: 30px;
}

.max-40 {
  max-width: 40px;
}

.min-h-90 {
  min-height: 100vh;
}

.border-left {
  border-left: 1px solid lightgray;
}

.blue-text {
  color: #17a2b8;
}

.uk-panel h1 {
  color: #3e416d;
}

.no-suggestions {
  padding: 0.5rem;
}

.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: calc(300px + 1rem);
}

.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  cursor: pointer;
  font-weight: 700;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}

.academic-year a.active{
  background-color: lightblue;
}

iframe#jitsiConferenceFrame0 {
  height: 100vh !important;
}