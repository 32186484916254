.app-dashboard-details-main-container {
    .app-calendar-main-container {
        padding: 0 15px 15px;

        .rbc-toolbar {
            .rbc-btn-group {
                button {
                    font-size: 12px;
                    font-weight: 600;

                }
                
                button.rbc-active {
                    background-color: #3e416c;
                    color: #fff;
                }
            }

            .rbc-toolbar-label {
                font-size: 16px;
                font-weight: 600;
                color: #3e416c;
            }
        }

        .rbc-month-view {
            border-radius: 10px;
            margin-top: 10px;
            .rbc-month-header {
                .rbc-header {
                    background-color: #3e416c;
                    color: #fff;
                    border: none;
                    padding: 10px;
                    font-size: 13px;
                    &:first-child {
                        border-top-left-radius: 10px;
                    }

                    &:last-child {
                        border-top-right-radius: 10px;
                    }
                }
            }

            .rbc-month-row {
                .rbc-row-bg {
                    .rbc-day-bg {

                    }
                }

                .rbc-row-content {
                    .rbc-row  {
                        .rbc-date-cell {
                            font-size: 16px;
                            font-weight: 600;
                            margin-top: 5px;
                            margin-right: 15px;
                        }
                    }
                }
            }

        }
        
        .rbc-event {
            background-color: #3e416c;
            font-size: 12px;
            font-weight: 600;
            text-align: center;
        }

        .rbc-time-header {

            .rbc-label.rbc-time-header-gutter {
                width: 67.2px !important;
                min-width: 67.2px !important;
            }
            .rbc-time-header-content {
                .rbc-row.rbc-time-header-cell {
                    .rbc-header {
                        background-color: #3e416c;
                        color: #fff;
                        font-weight: 600;
                        font-size: 12px;
                        height: 35px;
                        border-bottom: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .rbc-header.rbc-today {
                        background-color: #eaf6ff;
                        color: #333;
                    }
                }
            }
        }

        .rbc-time-content {
            .rbc-timeslot-group {
                .rbc-time-slot {
                    .rbc-label {
                        font-size: 12px;
                        font-weight: 600;
                    }
                }
            }
        }
    }
}