.app-dashboard-details-main-container {
    .live-classess-tab-container {
        padding: 15px 15px 0;
        ul {
            list-style-type: none;
            padding-left: 0;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 0;
            
            li {
                padding-left: 0;
                a {
                    font-size: 14px;
                    color: #3e416c !important;
                    font-weight: 600;
                    display: flex;
                    align-items: center;
                    padding: 10px 20px;
                    i {
                        padding-right: 5px;
                        font-size: 18px;
                    }
                }
            }

            li.uk-active,
            li.active {
                background-color: #3e416c;
                border-radius: 5px 5px 0 0;
                a {
                    color: #fff !important;
                }
            }
            
        }
    }

    .live-class-list-details-container {
        padding: 0 15px 15px;
        .lcld-flex-container {
            border: 1px solid #f1f1f1;
            padding: 15px;

            .live-class-card-container {
                background-color: #fff;
                box-shadow: 0.5pt 10px 14px -0.09375in rgba(25,42,70,.13);
                border-radius: 10px;
                padding: 15px;
                border: 1px solid #fafafa;
                margin-bottom: 15px;
                .bread-crumbs-section#breadcrumbs {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    li {
                        a {
                            font-size: 10px;
                            font-weight: 600;
                            color: #3e416c;
                        }
                    }
                }

                .lc-flex-container {
                    display: flex;
                    justify-content: flex-start;
                    align-items: stretch;
                    margin-top: 10px;

                    .lc-left-side-section {
                        flex: 0 350px;
                        border-radius: 10px;
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        align-items: flex-start;
                        margin-right: 20px;
                        img {
                            max-width: 100%;
                            max-height: 200px;
                            border-radius: 10px;
                        }
                    }

                    .lc-right-side-section {
                        width: calc(100% - 365px);
                        position: relative;
                        h4 {
                            margin: 0 0 5px;
                            a {
                                font-size: 18px;
                                color: #3e416c;
                                font-weight: 600;
                            }
                        }

                        .live-class-time {
                            font-size: 13px;
                            margin-bottom: 5px;
                            font-weight: 600;
                            color: #414242;
                            i {
                                padding-right: 5px;
                            }
                        }

                        .live-class-agenda {
                            background-color: #fafafa;
                            padding: 15px;
                            border-radius: 5px;
                            h6 {
                                margin: 0;
                                font-size: 13px;
                            }
                            ul {
                                padding-left: 0;
                                list-style-type: none;
                                margin-bottom: 0;

                                li {
                                    font-size: 12px;
                                    font-weight: 600;
                                    margin-top: 5px;
                                    i {
                                        padding-right: 5px;
                                    }
                                }
                            }
                        }

                        .join-now-button {
                            position: absolute;
                            right: 0px;
                            top: 0px;

                            a {
                                background-color: #3e416c;
                                border-color: #3e416c;
                            }
                        }

                        .live-class-by {
                            font-size: 14px;
                            margin-top: 5px;
                            font-weight: 500;

                            a {
                                font-weight: 600;
                                color: #3e416c;
                            }
                        }

                        .activity-link-text {
                            background-color: #3e416c;
                            display: inline-block;
                            padding: 6px 10px;
                            border-radius: 5px;
                            a {
                                font-weight: 600;
                                color: #fff;
                                font-size: 12px;
                                display: flex;
                                align-items: center;
                                i {
                                    font-size: 18px;
                                    padding-left: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .live-class-activity-details-container {
        padding: 15px;
        .lcdc-top-container {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: 20px;
            .lcdc-left-side {
                flex: 0 0 350px;
                margin-right: 25px;
            }

            .lcdc-right-side {
                width: calc(100% - 375px);

                h2 {
                    margin: 10px 0 0;
                    font-size: 22px;
                    color: #3e416c;
                }

                .course-details-info {
                    .lc-info {
                        margin-bottom: 5px;
                        font-size: 14px;

                        i {
                            padding-right: 5px;
                        }
                    }
                }
            }
        }

        .live-class-tabs-content-main-container {
            padding: 15px;

            table {
                tr.table-header-row {
                    th {
                        text-transform: unset;
                        font-size: 14px;
                        color: #fff;
                        font-weight: 600;
                        background-color: #3e416c;
                        padding: 8px 10px;

                        &:first-child {
                            border-top-left-radius: 10px;
                        }

                        &:last-child {
                            border-top-right-radius: 10px;
                        }
                    }
                }

                tr.table-data-row {
                    td {
                        font-size: 12px;
                        color: #414242;
                        font-weight: 600;
                        border-bottom: 1px solid #f1f1f1;

                        &:first-child {
                            border-top-left-radius: 10px;
                        }

                        &:last-child {
                            border-top-right-radius: 10px;
                        }
                    }
                }
            }
            .filter-select-section {
                display: flex;
                align-items: center;
                .filter-label-name {
                    font-size: 14px;
                    color: #3e416c;
                    font-weight: 600;
                    margin: 0;
                }
    
                select {
                    font-size: 14px;
                    font-weight: 600;
                    margin: 0;
                }
            }

            .lc-more-details-container {
                display: flex;
                justify-content: space-between;
                align-items: stretch;

                .left-side-container {
                    flex: 0 0 48%;

                    ul {
                        li {
                            label {
                                font-size: 14px;
                                font-weight: 600;
                                color: #414242;

                                .colored-text {
                                    color: #3e416c;
                                    font-weight: 600;
                                    padding-left: 5px;
                                }
                            }
                        }
                    }
                }

                .right-side-container {
                    flex: 0 0 48%;

                    h6 {
                        font-size: 14px;
                        margin-bottom: 0;
                    }
                    ul {
                        list-style-type: none;
                        padding-left: 0;

                        li {
                            margin-bottom: 5px;
                            font-size: 12px;
                            color: #414242;
                            font-weight: 600;

                            i {
                                padding-right: 5px;
                            }
                        }
                    }
                }
            }
        }
    }

    .add-live-class-form-container {
        padding: 15px;

        .choose-file-section {
            height: 245px;
            display: flex;
            justify-content: center;
            align-items: center;
            label {
                margin-top: 0;
                border-radius: 5px;
                cursor: pointer;
                margin-bottom: 0;
                padding: 40px;

                i {
                    font-size: 42px;
                }
            }
            input {
                display: none;
            }
        }


        .add-live-class-form-container-basic-details{
            select {
                height: 2.875pc;
            }

            h6 {
                font-size: 14px;
            }
        }
    }

    .points-to-cover-accordion {
        margin: 15px;
        border: 1px solid #f1f1f1;
    }

    .save-live-class-button-section {
        padding: 20px 0;
    }

    .live-class-streaming-container {
        // height: calc(100vh - 200px);
        position: relative;

        .lc-extra-options-container {
            position: absolute;
            right: 0;
            top: 0;
            background: rgba(255, 255, 255, 0.9);
            height: 530px;
            width: 350px;

            .close-popup-container {
                position: absolute;
                top: 10px;
                right: 10px;
                z-index: 1005;

                 i {
                     font-size: 24px;
                     cursor: pointer;
                 }
            }

            ul.uk-tab {
                margin-right: 15px;
                margin-left: 0;
                li {
                    padding-left: 0;
                }
            }

            ul.uk-switcher {
                li {
                    padding: 15px;
                }
            }

            .message-content-inner {
                &::-webkit-scrollbar {
                    width: 5px;
                }
            }
        }
        .streaming-container {
            height: 100%;
            padding: 15px;
            .live-class-body-container {
                height: 100%;
                .lc-body {
                    height: 100%;
                    position: relative;
                }

                #self-video {
                    position: relative;
                    width: 100%;

                    .media-container {
                        height: 500px;
                        width: 100% !important;
                        border: none !important;
                        margin: auto;

                        .media-box {
                            border: none !important;
                        }
                    }
                }

                .live-class-footer-container {
                    position: absolute;
                    width: 100%;
                    bottom: 30px;

                    button {
                        border-radius: 50%;
                        width: 50px;
                        height: 50px;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }
    }
    .app-page-header-right {
        .btn-animated-y .btn-inner--hidden {
            left: -17px;
        }
    }
}


@media screen and (max-width: 1024px) {
    .app-dashboard-details-main-container {
        .live-class-list-details-container {
            padding: 0 15px 15px;
            .lcld-flex-container {
                .live-class-card-container {
                    .lc-flex-container {
                        display: flex;
                        justify-content: flex-start;
                        flex-wrap: wrap;
                        align-items: flex-start;
                        margin-top: 10px;

                        .lc-left-side-section {
                            flex: 0 0 100%;
                            border-radius: 10px;
                            text-align: center;
                            display: flex;
                            justify-content: center;
                            align-items: flex-start;
                            margin-right: 20px;
                            img {
                                max-width: 100%;
                                height: auto;
                                border-radius: 10px;
                            }
                        }

                        .lc-right-side-section {
                            flex: 0 0 100%;
                            margin-top: 20px;
                            position: relative;
                            h4 {
                                margin: 0 0 5px;
                                a {
                                    font-size: 18px;
                                    color: #3e416c;
                                    font-weight: 600;
                                }
                            }

                            .live-class-time {
                                font-size: 13px;
                                margin-bottom: 5px;
                                font-weight: 600;
                                color: #414242;
                                i {
                                    padding-right: 5px;
                                }
                            }

                            .live-class-agenda {
                                background-color: #fafafa;
                                padding: 15px;
                                border-radius: 5px;
                                h6 {
                                    margin: 0;
                                    font-size: 13px;
                                }
                                ul {
                                    padding-left: 0;
                                    list-style-type: none;
                                    margin-bottom: 0;

                                    li {
                                        font-size: 12px;
                                        font-weight: 600;
                                        margin-top: 5px;
                                        i {
                                            padding-right: 5px;
                                        }
                                    }
                                }
                            }

                            .join-now-button {
                                position: absolute;
                                right: 0px;
                                top: 0px;

                                a {
                                    background-color: #3e416c;
                                    border-color: #3e416c;
                                }
                            }

                            .live-class-by {
                                font-size: 14px;
                                margin-top: 5px;
                                font-weight: 500;

                                a {
                                    font-weight: 600;
                                    color: #3e416c;
                                }
                            }

                            .activity-link-text {
                                background-color: #3e416c;
                                display: inline-block;
                                padding: 6px 10px;
                                border-radius: 5px;
                                a {
                                    font-weight: 600;
                                    color: #fff;
                                    font-size: 12px;
                                    display: flex;
                                    align-items: center;
                                    i {
                                        font-size: 18px;
                                        padding-left: 5px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .app-dashboard-details-main-container {
        .live-class-list-details-container {
            padding: 0 15px 15px;
            .lcld-flex-container {
                padding: 0;
                .live-class-card-container {
                    .lc-flex-container {
                        display: flex;
                        justify-content: flex-start;
                        flex-wrap: wrap;
                        align-items: flex-start;
                        margin-top: 10px;

                        .lc-left-side-section {
                            flex: 0 0 100%;
                            border-radius: 10px;
                            text-align: center;
                            display: flex;
                            justify-content: center;
                            align-items: flex-start;
                            margin-right: 20px;
                            img {
                                max-width: 100%;
                                height: auto;
                                border-radius: 10px;
                            }
                        }

                        .lc-right-side-section {
                            flex: 0 0 100%;
                            margin-top: 20px;
                            position: relative;
                            h4 {
                                margin: 0 0 5px;
                                margin-top: 40px;
                                a {
                                    font-size: 18px;
                                    color: #3e416c;
                                    font-weight: 600;
                                }
                            }

                            .live-class-time {
                                font-size: 13px;
                                margin-bottom: 5px;
                                font-weight: 600;
                                color: #414242;
                                i {
                                    padding-right: 5px;
                                }
                            }

                            .live-class-agenda {
                                background-color: #fafafa;
                                padding: 15px;
                                border-radius: 5px;
                                h6 {
                                    margin: 0;
                                    font-size: 13px;
                                }
                                ul {
                                    padding-left: 0;
                                    list-style-type: none;
                                    margin-bottom: 0;

                                    li {
                                        font-size: 12px;
                                        font-weight: 600;
                                        margin-top: 5px;
                                        i {
                                            padding-right: 5px;
                                        }
                                    }
                                }
                            }

                            .join-now-button {
                                position: absolute;
                                right: 0px;
                                top: 0px;

                                a {
                                    background-color: #3e416c;
                                    border-color: #3e416c;
                                }
                            }

                            .live-class-by {
                                font-size: 14px;
                                margin-top: 5px;
                                font-weight: 500;

                                a {
                                    font-weight: 600;
                                    color: #3e416c;
                                }
                            }

                            .activity-link-text {
                                background-color: #3e416c;
                                display: inline-block;
                                padding: 6px 10px;
                                border-radius: 5px;
                                a {
                                    font-weight: 600;
                                    color: #fff;
                                    font-size: 12px;
                                    display: flex;
                                    align-items: center;
                                    i {
                                        font-size: 18px;
                                        padding-left: 5px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}