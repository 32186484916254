.app-dashboard-details-main-container {
    .ac-doubts-list-container {
        padding: 15px;
        .ac-doubt-list-card {
            background-color: #d6e2fc70;
            margin-bottom: 15px;
            padding: 15px;
            border-radius: 10px;

            .doubt-heading {
                font-size: 16px;
                margin: 0;
                color: #3e416c;
                font-weight: 600;
            }

            .extra-details-section {
                p {
                    font-size: 12px;
                    color: #414242;
                    font-weight: 600;
                }
            }
        }
    }

    .go-back-section {
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 600;
        cursor: pointer;
    }

    .doubt-details-page-container {
        padding: 15px;

        .doubt-basic-info-container {
            background-color: #d6e2fc70;
            padding: 15px;
            border-radius: 10px;
            .doubt-heading {
                margin: 0;
                font-size: 16px;
                font-weight: 600;
                text-transform: unset;
            }

            .doubt-desc {
                font-size: 14px;
                color: #414242;
                margin: 0;
                text-transform: unset;
            }
            
            .doubts-meadia-attachments {
                max-width: 500px;
                h6 {
                    margin: 5px 0 0;
                    font-size: 14px;
                }

                .uk-slider-container {
                    padding: 20px 30px;
                    padding-left: 0;
                }
            }

            .doubts-extra-info-section {
                padding: 0px;
                p {
                    font-size: 12px;
                    color: #414242;
                    font-weight: 600;
                }
            }

        }

        .doubt-answers-container {
            margin-top: 20px;
            .answer-heading {
                padding: 10px;
                margin: 0;
                background-color: #3e416c;
                color: #fff;
                font-size: 16px;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }

            .answer-card-list-container {
                padding: 15px;
                border: 1px solid #f1f1f1;
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
                .answer-card-flex {
                    margin-bottom: 15px;
                    border: 1px solid #f1f1f1;
                    border-radius: 10px;
                    padding: 10px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;

                    .as-left-side {
                        width: 80px;
                        margin-right: 15px;
                        div {
                            text-align: center;
                            img {
                                border-radius: 50%;
                                width: 60px;
                                height: 60px;
                                border: 1px solid #f1f1f1;
                            }
                        }
                    }

                    .as-right-side {
                        width: calc(80% - 100px);

                        .comment-by-info-section {
                            .commentor-name {
                                font-size: 14px;
                                margin: 0;
                            }

                            .user-type {
                                font-size: 12px;
                                color: #414242;
                                font-weight: 600;
                            }

                            .date {
                                font-size: 10px;
                                color: #717272;
                            }
                        }

                        .comment-desc {
                            font-size: 12px;
                        }

                        .referamce-media-container {
                            h6 {
                                font-size: 12px;
                            }

                            .uk-slider-container {
                                padding: 20px 30px;
                                padding-left: 0;
                            }
                        }
                    }
                }
            }
        }

        .doubt-submit-answer-section {
            margin-top: 20px;
            .heading {
                font-size: 16px;
            }

            .subimt-answer-form {
                display: flex;
                justify-content:flex-start;
                align-items: flex-start;

                .sa-left-side {
                    width: 80px;

                    div {
                        text-align: center;
                        img {
                            border-radius: 50%;
                            width: 60px;
                            height: 60px;
                            border: 1px solid #f1f1f1;
                        }
                    }
                }

                .sa-right-side {
                    width: calc(100% - 100px);

                    .media-heading {
                        font-size: 14px;
                        margin: 0 0 5px;
                    }

                    .submit-button {
                        padding-left: 15px;
                    }
                }
            }
        }
    }

    .add-new-doubt-form-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .and-left-side {
            flex: 0 0 48%;

            .form-group {

                h6 {
                    margin: 0;
                    font-size: 14px;
                    text-transform: unset;
                }
                label {
                    font-size: 14px;
                    font-weight: 600;
                    color: #3e416c;
                    margin-bottom: 3px;
                }

                input, textarea {
                    border: 1px solid #e1e1e1;
                }

                .choose-file-section {
                    label {
                        background-color: #dddddd;
                        padding: 9px 15px;
                        margin-top: 0;
                        border-radius: 5px;
                        cursor: pointer;
                        margin-bottom: 0;
                        display: inline-block;
                    }
                    input {
                        display: none;
                    }
                }

                #multiselectContainerReact {
                    div {
                        &:first-child {
                            border: none;
                            padding: 0;

                            span {
                                background-color: #3e416c;
                                font-size: 10px;
                                color: #fff;
                                font-weight: 600;
                                border-radius: 30px;
                            }

                            input#search_input {
                                border: 1px solid #e1e1e1 !important;
                            }
                        }
                    }
                }

                select {
                    height: 2.875pc;
                    border: 1px solid #e1e1e1;
                }
            }
        }

        .and-right-side {
            flex: 0 0 48%;

            .form-group {
                label {
                    font-size: 14px;
                    font-weight: 600;
                    color: #3e416c;
                    margin-bottom: 3px;
                }

                input, textarea {
                    border: 1px solid #e1e1e1;
                }

                #multiselectContainerReact {
                    div {
                        &:first-child {
                            border: none;
                            padding: 0;

                            span {
                                background-color: #3e416c;
                                font-size: 10px;
                                color: #fff;
                                font-weight: 600;
                                border-radius: 30px;
                            }

                            input#search_input {
                                border: 1px solid #e1e1e1 !important;
                            }
                        }
                    }
                }

                select {
                    height: 2.875pc;
                    border: 1px solid #e1e1e1;
                }
            }

            .submit-button-section {
                margin-top: 30px;
                button {
                    height: 40px;
                }
            }
        }
    }
}