.app-dashboard-details-main-container {
    .fee-details-main-container {
        padding: 15px;
    }

    .fee-data-table-container {
        margin-top: 15px;
        table {
            tr.table-header-row {
                th {
                    background-color: #3e416c;
                    color: #fff;
                    font-size: 14px;
                    font-weight: 600;
                    text-transform: unset;
                    padding: 8px 10px;

                    &:first-child {
                        border-top-left-radius: 10px;
                    }

                    &:last-child {
                        border-top-right-radius: 10px;
                    }
                }
            }

            tr.table-data-row {
                td {
                    font-size: 12px;
                    color: #414242;
                    font-weight: 600;
                }
            }
        }
    }
}