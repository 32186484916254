.participants-list-main-container {
  background-color: #fff;
  position: absolute;
  z-index: 1002;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  padding-top: 40px;
  padding-bottom: 75px;
  height: calc(100% - 20px);
  overflow-y: auto; 
  font-family: 'Raleway', sans-serif !important;
  .participants-header-container {
    padding: 10px 15px;
    box-shadow: 0 3px 5px #ddd;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-side {
      h4 {
        margin: 0;
        font-size: 18px;
      }
    }

    .right-side {
      i {
        font-size: 28px;
        color: #3e416c;
      }
    }
  }

  .participants-body-section {
    .collapse-section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #ddd;
      padding: 10px 15px;
      cursor: pointer;

      h4.heading {
        margin: 0;
        font-size: 16px;
      }

      .rside-collapse {
        font-size: 24px;
      }
    }

    .p-body-section {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-bottom: 1px solid #f1f1f1;
      padding: 10px;

      .lside {
        width: 40px;
        .l-circle {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: #3e416c;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          font-size: 20px;
          text-transform: uppercase;
          font-weight: 600;
        }

        .l-circle.joined-circle {
          background-color: #50b37e;
        }

        .l-circle.not-joined-circle {
          background-color: #ee5335;
        }
      }

      .rside {
        font-size: 15px;
        margin-left: 10px;
        font-weight: 500;
        text-transform: capitalize;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .participants-list-main-container {
    width: 100%;
    height: 100%;
    position: fixed;
  }
}