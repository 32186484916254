.app-dashboard-details-main-container {
    .video-lecture-chat-container {
        padding: 15px;
        .message-content {
            .message-reply  {
                padding: 15px 15px 0;

                button {
                    width: 75px;
                    height: 30px;
                }
            }
        }
    }
}