.app-dashboard-details-main-container {
    .leaves-list-data-main-container {
        padding: 15px;

        table {
            tr.table-header-row {
                th {
                    background-color: #3e416c;
                    padding: 8px 10px;
                    font-size: 14px;
                    font-weight: 600;
                    color: #fff;
                    text-align: center;
                    border: none;

                    &:first-child {
                        border-top-left-radius: 10px;
                    }

                    &:last-child {
                        border-top-right-radius: 10px;
                    }
                }
            }

            tr.table-data-row {
                td {
                    font-size: 12px;
                    font-weight: 600;
                    color: #414242;
                    text-align: center;
                    button {
                        border-radius: 15px;
                    }
                }
            }
        }
    }

    .holiday-list-table-container {
        padding: 15px;

        table {
            tr.table-header-row {
                th {
                    background-color: #3e416c;
                    padding: 8px 10px;
                    font-size: 14px;
                    font-weight: 600;
                    color: #fff;
                    text-align: center;
                    border: none;

                    &:first-child {
                        border-top-left-radius: 10px;
                    }

                    &:last-child {
                        border-top-right-radius: 10px;
                    }
                }
            }

            tr.table-data-row {
                td {
                    font-size: 12px;
                    font-weight: 600;
                    color: #414242;
                    text-align: center;
                    button {
                        border-radius: 15px;
                    }
                }
            }
        }
    }

    .apply-leave-form-section {
        padding: 15px;
        max-width: 600px;
        .card-content {
            .date-flex-section {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .df-left-side {
                    flex: 0 0 48%;

                    h5 {
                        font-size: 14px;
                    }
                }

                .df-right-side {
                    flex: 0 0 48%;


                    h5 {
                        font-size: 14px;
                    }
                }
            }

            .reason-input-section {
                margin-top: 15px;
                h5 {
                    font-size: 14px;
                }
            }

            .message-input-section {
                margin-bottom: 15px;
                h5 {
                    font-size: 14px;
                }
            }

            .bottom-buttons-section {
                text-align: right;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .app-leaves-xs-list {
        .leave-row {
            background-color: #fafafa;
            margin-bottom: 10px;
            border-radius: 10px;
            padding: 10px;
            position: relative;
            border: 1px solid #f1f1f1;

            .status-badge {
                position: absolute;
                left: 0;
                top: 0;
                height: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #3e416c;
                color: #fff;
                font-size: 12px;
                font-weight: 600;
                border-radius: 10px 0 10px 0;
                padding: 0 10px;
            }

            .xs-leave-base-details {
                margin-top: 20px;
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                margin-bottom: 10px;
                p {
                    font-size: 14px;
                    color: #3e416c;
                    font-weight: 600;

                    &:last-child {
                        color: #3c9be2;
                        font-size: 12px;
                    }
                }
            }

            .buttons-row {
                button {
                    border-radius: 20px;
                }
            }
        }
    }
}