.no-results-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 40px);
  min-height: 150px;

  svg {
    width: 75px;
    height: 75px;
  }

  p {
    margin: 10px 0 0;
    font-size: 14px;
    font-weight: 600;
  }
}