.app-dashboard-details-main-container {
    .studets-filters-container {
        padding: 15px 15px 0;

        select {
            background-color: #f1f1f1;
        }
    }
    .students-data-list-table-container {
        padding: 0 15px 15px;

        table {
            thead {
                tr.students-list-container-header {
                    th {
                        padding: 8px 15px;
                        font-size: 12px;
                        background-color: #3e416c;
                        color: #fff;
                        border: none;
                        &:nth-child(1) {
                            border-top-left-radius: 10px;
                        }
                        &:last-child {
                            text-align: center;
                            border-top-right-radius: 10px;
                        }
                    }
                }
            }

            tr.students-list-container-row {
                td {
                    font-size: 12px;
                    color: #414242;
                    font-weight: 600;
                    &:last-child {
                        width: 15%;

                        .action-items {
                            text-align: center;

                            button {
                                width: 35px;
                                height: 35px;
                                padding: 0;
                                justify-content: center;
                                display: inline-flex;
                                align-items: center;
                                border-radius: 30px;
                                border: none;
                                &:first-child {
                                    margin-right: 10px;
                                    background-color: #3e416c;
                                }
                            }
                        }
                    }
                }


                td.performance-td {
                    a {
                        color: #3e416c;
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .parents-info-container {
        padding: 0 15px 15px;

        .parent-info-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .pih-left-side {
                font-size: 16px;
                cursor: pointer;
                font-weight: 600;
                color: #3e416c;
            }

            .pih-right-side {
                button {
                    background-color: #3e416c;
                    padding: 2px 8px;
                    border-radius: 5px;
                    border: 2px solid #3e416c;
                    color: #fff;
                    font-size: 12px;
                    font-weight: 600;
                    font-family: 'gotham-rounded', 'helvetica neue' ,helvetica;
                    cursor: pointer;
                    outline: none;
                    i {
                        padding-right: 5px;
                    }
                }
            }
        }

        .parent-info-card-container {
            margin-top: 15px;
            box-shadow: 0.5pt 10px 14px -0.09375in rgba(25,42,70,.13);
            padding: 15px;
            border-radius: 10px;
            border: 1px solid #f1f1f1;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .pih-left-side {
                width: 100px;
                margin-right: 20px;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                }
            }

            .pih-right-side {
                width: calc(100% - 115px);

                h3 {
                    font-size: 16px;
                    margin: 0;
                }

                p {
                    font-size: 12px;
                    text-transform: capitalize;
                    margin: 0;
                }

                h6 {
                    font-size: 12px;

                    i {
                        padding-right: 5px;
                    }
                }
            }
        }
    }

    .student-parents-list-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 15px;

        .parent-info-card {
            width: calc(100% * 1 / 2.001 - 20px);
            margin: 10px;
            border-radius: 10px;
            padding: 15px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            background-color: #fff;
            box-shadow: 0.5pt 10px 14px -0.09375in rgba(25,42,70,.13);
            box-sizing: border-box;
            border: 1px solid #f1f1f1;
            .parent-info-card-left-side {
                flex: 0 0 100px;

                img {
                    max-width: 80px;
                    height: auto;
                }
            }

            .parent-info-card-right-side {
                h3 {
                    font-size: 16px;
                    margin-bottom: 0;
                }

                span {
                    font-size: 14px;
                    font-weight: 600;
                }

                h6 {
                    font-size: 14px;
                    i {
                        padding-right: 5px;
                    }
                }
            }
        }
    }
}