.app-dashboard-details-main-container {
    .acg-loader-container {
        margin-top: 15px;
    }

    .grading-list-details-container {
        .switcher-details-container {
            padding: 15px;

            .grading-info-main-container {
                p {
                    font-size: 14px;
                    font-weight: 600;
                    color: #3e416c;
                    margin-bottom: 15px;

                    button {
                        background-color: #3e416c;
                        border-radius: 5px;
                        border-color: #3e416c;
                    }
                }

                .grades-system-flex {
                    width: 100%;
                    overflow-x: auto;

                    table {
                        tr {
                            td.grade-info-td {
                                button {
                                    h5 {
                                        font-size: 16px;
                                        font-weight: 600;
                                        text-align: center;
                                        margin: 0;
                                        padding: 3px 0;
                                        border-bottom: 1px solid #ddd !important
                                    }

                                    p {
                                        font-size: 10px;
                                        padding-top: 5px;
                                        font-weight: 500;
                                        color: #333;
                                        margin: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}