.app-dashboard-details-main-container {
    .ot-class-info-container {
        padding: 15px;
        h6 {
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 8px;
        }
    }

    .ot-years-list-container {
        .ot-year-name {
            a {
                font-size: 14px;
                font-weight: 600;
                color: #3e416c;
            }
        }
    }

    .ot-list-details-container {
        .ot-list-detail-flex {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            padding: 0 15px;
            .ot-list-left-side {
                width: 80px;
                margin-right: 10px;
            }

            .ot-list-right-side {
                width: calc(100% - 90px);

                .test-details {
                    .test-details-header {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        h5 {
                            font-size: 16px;
                            display: flex;
                            align-items: center;
                            margin: 0;
                            .test-type-badge {
                                background-color: #3e416c;
                                border: 1px solid #3e416c;
                                font-size: 8px;
                                font-weight: 600;
                                color: #fff;
                                border-radius: 30px;
                                margin: 0;
                                margin-left: 10px;
                                padding: 2px 10px;
                                display: inline-block;
                            }
                        }
                    }

                    p {
                        font-size: 12px;
                        font-weight: 600;
                        color: #414242;
                    }

                    .ot-extra-details-info {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        div {
                            margin-right: 15px;
                            font-weight: 600;
                            font-size: 12px;
                            color: #3e416c;

                            i {
                                padding-right: 5px;
                            }

                            &:nth-child(2) {
                                text-transform: capitalize;
                            }
                        }
                    }
                }
            }
        }
    }
}

.app-test-review-page-main-container {
    .atr-top-flex-container {
        height: 100vh;
        background-color: #FFF;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        .atr-left-side  {
            width: calc(100% - 400px);
            border-right: 1px solid #ddd;
            .atr-page-header {
                padding: 15px;
                position: relative;
                .card-title {
                    font-size: 18px;
                    margin: 0;
                    position: relative;
                    
                    .instructions-icon {
                        position: absolute;
                        top: 10px;
                        right: 10px;
                        font-size: 24px;
                    }

                    i {
                        padding-right: 5px;
                    }
                }


                .time-taken {
                    font-size: 12px;
                    color: #414242;
                    font-weight: 600;
                }

                .score-bubble-section {
                    position: absolute;
                    width: 100px;
                    right: 30px;
                    top: 10px;
                    text-align: center;

                    h4 {
                        margin: 0;
                        font-size: 14px;
                    }

                    .score-bubble {
                        width: 60px;
                        height: 60px;
                        background-color: #fff;
                        border: 1px solid #3e416c;
                        border-radius: 50%;
                        margin: auto;

                        span {
                            display: block;
                            text-align: center;
                            font-size: 16px;
                            font-weight: 600;
                            color: #3e416c;
                        }

                        span.bottom {
                            border-top: 1px solid #9295c5
                        }
                    }
                }
            }

            .question-number-text {
                padding: 5px 15px;
                text-align: center;
                color: #3e416c;
                font-weight: 600;
                font-size: 18px;
            }

            .app-ot-questions-main-container {
                border-top: 1px solid #f1f1f1;
                padding: 15px;
                background-color: #fafafa;

                .ot-question-number-heading {
                    font-size: 12px;
                    margin: 0 0 10px;
                    text-transform: unset;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                .question-section {
                    color: #333;
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 28px;
                }

                .match-the-following-question {
                    .mtf-heading {
                        font-size: 14px;
                    }

                    .mtf-flex {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;
                        background-color: #f1f1f1;
                        padding: 15px;

                        .mtf-left-side {
                            flex: 0 0 45%;
                            text-align: right;

                            .mtf-left-heading {
                                font-size: 16px;
                                font-weight: 600;
                            }

                            .mtf-value {
                                margin-bottom: 10px;

                                span {
                                    font-size: 14px;
                                    font-weight: 600;
                                    color: #414242;

                                    &:first-child {
                                        padding-right: 5px;
                                    }
                                }
                            }
                        }

                        .mtf-right-side {
                            flex: 0 0 45%;

                            .mtf-right-heading {
                                font-size: 16px;
                                font-weight: 600;
                            }

                            .mtf-value {
                                margin-bottom: 10px;

                                span {
                                    font-size: 14px;
                                    font-weight: 600;
                                    color: #414242;

                                    &:first-child {
                                        padding-right: 5px;
                                    }
                                }
                            }
                        }
                    }
                }

                .question-options-list-container {
                    margin-top: 15px;

                    li {
                        padding: 10px;
                        background-color: #fff;
                        border: 1px solid #f1f1f1;
                        border-radius: 5px;
                        margin-bottom: 10px;

                        .ot-q-option-checkbox-row {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;

                            input {
                                width: 20px !important;
                                height: 20px !important;
                                margin-bottom: 0;
                            }

                            label {
                                cursor: pointer;
                                margin-left: 10px;
                                font-weight: 600;
                                margin-bottom: 0;
                                font-size: 14px;
                                display: inline-block;
                            }
                        }
                    }
                }

                .ot-q-extra-info-text-section {
                    margin-top: 20px;
                    span {
                        font-size: 14px;
                        font-weight: 600;
                    }
                }

                .ot-q-buttons-container {
                    button.next-button {
                        background-color: #3e416c;
                        border-color: #3e416c;
                        color: #fff;
                    }

                    button.previous-button {
                        background-color: #3c9be2;
                        border-color: #3c9be2;
                        color: #fff;
                    }
                }

                .matches-with-options-container {
                    margin-top: 20px;
                    .flex-container {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        margin-bottom: 10px;

                        .left-side {
                            margin-right: 15px;
                            min-width: 150px;
                            text-align: left;

                            span {
                                font-size: 14px;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }

            .test-completed-info-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: 80vh;

                i {
                    font-size: 48px;
                    margin-bottom: 10px;
                }

                h4 {
                    text-transform: unset;
                }
            }
        }

        .atr-right-side {
            width: 400px;

            .remaining-time-text-container {
                padding: 15px;
                background-color: #3e416c;

                h6 {
                    color: #fff;
                    font-size: 16px;
                    margin: 0;
                }

                p {
                    margin-top: 10px;
                    padding-bottom: 10px;
                    display: flex;
                    align-items: center;
                    span {
                        width: 40px;
                        height: 40px;
                        border-radius: 5px;
                        background-color: #fff;
                        color: #3e416c;
                        display: inline-block;
                        font-weight: 600;
                        font-size: 14px;
                        margin: 0 5px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &:first-child {
                            margin-left: 0;
                        }
                    }

                    span.qolon {
                        width: auto;
                        background-color: unset;
                        color: #fff;
                        font-size: 18px;
                    }
                }
            }
            .atr-q-detail-container {
                padding: 15px;
                .atr-q-heading {
                    font-size: 16px;
                    margin-bottom: 5px;
                }

                .question-details-row {
                    button {
                        font-size: 14px !important;
                    }

                    button.question-button {
                        background-color: #3c9be2;
                        border-color: #3cb9e2;
                        color: #fff;
                    }
                }
            }
        }
    }

    .app-test-landing-page-container {
        padding: 15px;
        background-color: #fafafa;
        border-top: 1px solid #f1f1f1;

        .test-instructions-container {
            ul {
                list-style-type: none;
                padding-left: 0;
                li {
                    font-size: 14px;
                    font-weight: 600;
                    color: #414242;
                    margin-bottom: 10px;
                    line-height: 24px;
                    display: flex;
                    align-items: center;

                    i {
                        padding-right: 5px;
                    }
                }
            }

        }
        h6.info-heading {
            margin-top: 30px;
            font-size: 14px;
            text-transform: unset;
        }
    }
}

.add-online-test--details-container {
    padding: 15px;
    h6 {
        margin: 0 0 10px;
        font-size: 14px;

        span {
            color: #3c9be2;
            padding-left: 5px;
        }
    }

    .add-ot-tab-switcher-container {
        margin: 0 -15px;

        ul {
            li {
                font-size: 14px;
                font-weight: 600;
            }
        }
    }
    
    .ot-tab-content-container {
        .add-test-form-container {
            .row {
                label {
                    font-size: 14px;
                    color: #3e416c;
                    font-weight: 600;
                }

                select,
                input[type=date] {
                    height: 2.875pc;
                }

                #multiselectContainerReact {
                    div {
                        &:first-child {
                            border: none;
                            padding: 0;

                            span {
                                background-color: #3e416c;
                                font-size: 10px;
                                color: #fff;
                                font-weight: 600;
                                border-radius: 30px;
                            }

                            input#search_input {
                                border: 1px solid #e1e1e1 !important;
                            }
                        }
                    }
                }
            }

            .save-button-section {
                margin-top: 20px;

                button {
                    i {
                        padding-right: 5px;
                    }
                }
            }
        }
        
        
        .add-instructions-form-container {
            .ot-instruction-form {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                flex-wrap: wrap;
                border: 1px solid #f1f1f1;
                padding: 15px;
                
                .ot-instructions-form-row {
                    width: calc(100% * 1 / 2.001 - 20px);
                    margin: 10px;

                    .ot-instructions-form-card {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        input {
                            width: 80%;
                            margin: 0 !important;
                        }
                    }
                }

                .save-button-container {
                    i {
                        padding-right: 5px;
                    }
                }
            }
        }
    }

}

.app-test-submissions-list-container {
    padding: 15px;
    .test-details-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .test-details-left {
            flex: 0 0 50%;


            h6 {
                font-size: 15px;
                margin: 0 0 5px;

                span {
                    color: #3c9be2;
                }
            }
        }

        .test-details-right {
            flex: 0 0 50%;
            h6 {
                font-size: 15px;
                margin: 0 0 5px;

                span {
                    color: #3c9be2;
                }
            }
        }
    }
}

.app-test-submissions-list {
    padding: 15px;
    .at-sl-container {
        .at-sl-content {
            padding: 15px;
            border: 1px solid #f1f1f1;
            border-radius: 5px;
            margin-bottom: 10px;
            h5 {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .evaluate-badge {
                    position: absolute;
                    right: 160px;
                    padding: 5px 15px;
                    color: #3c9be2;
                    font-size: 12px;
                    font-weight: 600;
                    border-radius: 20px;
                }

                small {
                    font-weight: 600;
                    cursor: pointer;
                    background-color: #3e416c;
                    padding: 5px 15px;
                    color: #fff;
                    font-size: 12px;
                    font-weight: 600;
                    border-radius: 20px;
                }
            }

            p.details-info {
                span {
                    margin-right: 15px;
                    font-size: 12px;
                    font-weight: 600;
                    display: inline-flex;
                    align-items: center;
                    i {
                        font-size: 18px;
                        padding-right: 3px;
                        color: #3c9be2;
                    }
                }
            }
        }
    }
}

.app-selected-submission-details {
    padding: 15px;
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    .as-sd-top-details-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left-side {
            flex: 0 0 50%;
        }
        .right-side {
            flex: 0 0 50%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .l-side {
                flex: 0 0 70%;
                h5 {
                    margin: 0;
                    font-size: 14px;
                }

                select {
                    height: 2.875pc;
                }
            }

            .r-side {

            }
        }
    }

    .app-q-list-container {
        .main-container  {
            .flex-container {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                padding: 10px;
                background-color: #f1f1f1;

                div {
                    width: calc(100% * 1/4.0001);
                    margin: 5px 10px;

                    h5 {
                        font-size: 14px;
                        margin: 0 0 5px;
                    }

                    select {
                        height: 2.875pc;
                    }
                }
            }
        }
        .question-collapse-header {
            border: 1px solid #f1f1f1;
            border-radius: 10px 10px 0 0;
            margin-bottom: 15px;
    
            .card-header {
                label {
                    cursor: pointer;
                    color: #3e416c;
                }
            }
    
            .question-collapse {
                .save-button-container {
                    padding: 15px 30px;
                    button {
                        i {
                            padding-right: 5px;
                        }
                    }
                }
                .card-content {
                    .card-body {
    
                        .form-flex-row {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
    
                            .left-side {
                                width: 150px;
                                margin-right: 15px;
                                text-align: right;
    
                                label {
                                    font-size: 14px;
                                    font-weight: 600;
                                    color: #3e416c;
                                }
                            }
    
                            .right-side {
                                width: calc(100% - 165px);
                                max-width: 600px;
                                
                                select {
                                    display: inline-block;
                                    width: auto;
                                    height: 2.857pc;
                                }
    
                                .options-flex-container {
                                    display: flex;
                                    justify-content: flex-start;
                                    flex-wrap: wrap;
                                    .question-checkbox-options-container {
                                        width: calc(100% * 1 / 2.001 - 20px);
                                        margin: 0 10px;
                                        display: flex;
                                        justify-content: flex-start;
                                        align-items: center;
                                        margin-bottom: 10px;
    
                                        div {
                                            &:first-child {
                                                width: 20px;
                                                margin-right: 10px;
    
                                                input {
                                                    width: 20px !important;
                                                    height: 20px !important;
                                                    margin-bottom: 0;
                                                }
                                            }
                                        }
    
                                        label {
                                            width: calc(100% - 30px);
                                            input {
                                                max-width: 200px;
                                                margin-bottom: 0px;
                                            }
                                        }
                                    }
                                }
    
                            }
                        }
    
                        .form-flex-row.align-start {
                            align-items: flex-start;
                        }
    
                        .match-the-following-options-flex {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            margin-bottom: 10px;
    
                            .left-side {
                                min-width: 150px;
    
                                span {
                                    font-size: 14px;
                                    color: #414242;
                                    font-weight: 600;
                                }
                            }
    
                            .right-side {
                                margin-left: 10px;
                                width: calc(100% - 160px);
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .app-test-review-page-main-container {
        .atr-top-flex-container {
            flex-wrap: wrap;
            .atr-left-side {
                width: 100%;
                flex: 0 0 100%;
            }

            .atr-right-side {
                flex: 0 0 100%;
                width: 100%;
            }
        }
    }
}