.app-dashboard-details-main-container {
    .complaints-list-data-main-container {
        padding: 15px;

        table {
            tr.table-header-row {
                th {
                    background-color: #3e416c;
                    padding: 8px 10px;
                    font-size: 14px;
                    font-weight: 600;
                    color: #fff;
                    text-align: center;
                    border: none;

                    &:first-child {
                        border-top-left-radius: 10px;
                    }

                    &:last-child {
                        border-top-right-radius: 10px;
                    }
                }
            }

            tr.table-data-row {
                td {
                    font-size: 12px;
                    font-weight: 600;
                    color: #414242;
                    text-align: center;
                    button {
                        border-radius: 15px;
                    }
                }
            }
        }
    }

    .add-complaint-form-container {
        padding: 15px;
        max-width: 600px;
        .card-content {

            .reason-input-section {
                margin-top: 15px;
                h5 {
                    font-size: 14px;
                }
            }

            .bottom-buttons-section {
                text-align: right;
            }
        }
    }
}