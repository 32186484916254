.app-dashboard-details-main-container {
    .notice-list-container {
        padding: 15px;
        .notice-list-section {
            list-style-type: none;
            padding-left: 0;
            .notice-list-row {
                padding: 15px;
                background-color: #fff;
                border-bottom: 1px solid #f1f1f1;
                &:nth-child(odd) {
                    background-color: #fafafa;
                }

                &:last-child {
                    border-bottom: none;
                }
                .notice-heading {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin: 0 0 5px;

                    .date-info {
                        font-size: 10px;
                        color: #717272;
                    }
                }

                .notice-desc {
                    font-size: 14px;
                    color: #555;
                    line-height: 26px;
                }
            }
        }
    }
}