.progress-card-main-container {
 .card-content {
   max-width: 800px;
   width: 100%;
   margin: auto;
   background-color: #fff;
   padding: 40px;
   box-sizing: border-box;

   .pc-header {
     display: flex;
     justify-content: flex-start;
     align-items: flex-start;
     .pc-left-side {
        flex: 0 0 100px;
        height: 100px;
        img.school-image {
          max-width: 70px;
          max-height: 70px;
        }
     }

     .pc-right-side {
      text-align: center;
      width: calc(100% - 180px);

      .school-name {
        font-size: 28px;
        margin: 0;
      }

      .affliation-name {
        margin: 0;
        font-size: 15px;
        color: #333;
      }

      .address {
        font-size: 12px;
        color: #919292;
        margin: 0;
      }
     }
   }

   .pc-body {
     margin-top: 20px;
     border-top: 2px solid #fafafa;
     padding-top: 20px;
     .pc-body-header {
       h3 {
         margin: 0;
         font-size: 20px;
       }

       p {
         margin: 5px 0;
         padding: 8px 0;
         font-size: 16px;
         font-weight: 600;
         background-color: rgba(62, 65, 109, 0.8);
         padding-left: 15px;
         border-top-right-radius: 5px;
         border-top-left-radius: 5px;
         color: #fff
       }
     }

     .pc-student-details-container {
       display: flex;
       justify-content: space-between;
       align-items: center;
       padding-top: 15px;
       padding-bottom: 15px;

       .pcb-left-side {
         flex: 0 0 60%;
         font-weight: 600;
         padding-left: 10px;

         p {
           margin-bottom: 5px;
  
           span.value {
             font-weight: 400;
           }
         }
       }

       .pcb-right-side {
         flex: 0 0 30%;
         font-weight: 600;
         p {
          margin-bottom: 5px;
 
          span.value {
            font-weight: 400;
          }
        }
       }
     }

     .pc-student-results-table {
       padding-top: 20px;

       .table-header {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        background: rgba(62, 65, 109, 0.8);
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        div.table-tr {
          color: #f1f1f1;
          font-size: 15px;
          font-weight: 600;
          &:first-child {
            flex: 0 0 50%;
            display: flex;
            align-items: center;
            border-right: 1px solid #787992;
            padding-left: 15px;
          }
 
          &:nth-child(2) {
            flex: 0 0 15%;
            display: flex;
            align-items: center;
            border-right: 1px solid #787992;
            justify-content: center;
         }
         &:nth-child(3) {
           flex: 0 0 35%;

           .inner-heading {
            text-align: center;
            border-bottom: 1px solid #787992;
            padding: 5px 0;
           }

           .inner-div {
             display: flex;
             justify-content: space-between;
             align-items: center;

             .table-tr-inner {
               flex: 0 0 48%;
               text-align: center;
               padding: 5px 0;
               font-size: 12px;

               &:first-child {
                border-right: 1px solid #787992;
               }
             }
           }
         }
        }
       }

       .table-body {
        .subject-details-row {
          display: flex;
          justify-content: space-between;
          align-items: stretch;
          background: #fafafa;
          border-bottom: 1px solid #f1f1f1;
          border-left: 1px solid #f1f1f1;
          border-right: 1px solid #f1f1f1;
          
          &:last-child {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
          }

          div.table-tr {
            color: #616262;
            font-size: 14px;
            font-weight: 600;
            &:first-child {
              flex: 0 0 50%;
              display: flex;
              align-items: center;
              border-right: 1px solid #f1f1f1;
              padding-left: 15px;
            }
   
            &:nth-child(2) {
              flex: 0 0 15%;
              display: flex;
              align-items: center;
              border-right: 1px solid #f1f1f1;
              justify-content: center;
           }
           &:nth-child(3) {
             flex: 0 0 35%;
  
             .inner-heading {
              text-align: center;
              border-bottom: 1px solid #787992;
              padding: 5px 0;
             }
  
             .inner-div {
               display: flex;
               justify-content: space-between;
               align-items: center;
  
               .table-tr-inner {
                 flex: 0 0 48%;
                 text-align: center;
                 padding: 10px 0;
                 font-size: 12px;
  
                 &:first-child {
                  border-right: 1px solid #f1f1f1;
                 }
               }
             }
           }
          }
        }
       }
     }

     .pc-instructions-container {
       margin-top: 30px;

       h4 {
         font-size: 18px;
         color: #333;
         text-align: center;
         margin: 0 0 20px;
       }

       p {
         font-size: 14px;
         color: #616262;
       }

       .pc-grading-info-table {
         max-width: 350px;
         margin: 15px auto;
         .table-header {
          display: flex;
          justify-content: space-between;
          align-items: center;

          div {
            text-align: center;
            border: 1px solid #f1f1f1;
            font-size: 13px;
            font-weight: 600;
            padding: 6px 0;
            border-bottom: none;
            &:first-child {
              flex: 0 0 50%;
              border-right: none;
              border-top-left-radius: 5px;
            }

            &:nth-child(2) {
              flex: 0 0 50%;
              border-top-right-radius: 5px;
            }
          }
         }

         .table-body {
           .table-body-tr {
              display: flex;
              justify-content: space-between;
              align-items: stretch;

              div {
                border: 1px solid #f1f1f1;
                text-align: center;
                border-bottom: none;
                font-size: 12px;
                font-weight: 600;
                color: #919292;
                padding: 3px 0;
                &:first-child {
                  flex: 0 0 50%;
                  border-right: none;
                }

                &:nth-child(2) {
                  flex: 0 0 50%;
                }
              }

              &:last-child {
                div {
                  &:first-child {
                    flex: 0 0 50%;
                    border-bottom-left-radius: 5px;
                  }
  
                  &:nth-child(2) {
                    flex: 0 0 50%;
                    border-bottom-right-radius: 5px;
                  }
                  border-bottom: 1px solid #f1f1f1;
                }
              }
           }
         }
       }
     }

     .pc-extra-info-container {
       margin-top: 30px;
       display: flex;
       justify-content: space-between;
       align-items: stretch;

       .pce-left-side,
       .pce-right-side {
          flex: 0 0 48%;
          background-color: #fafafa;
          border: 1px solid #f1f1f1;
          border-radius: 5px;
         .pce-header {
           background-color: rgba(62, 65, 109, 0.8);
           padding: 8px 0;
           border-top-left-radius: 5px;
           border-top-right-radius: 5px;

           p {
             padding-left: 15px;
             color: #fff;
             font-weight: 600;
           }
         }

         .pce-body {
           padding: 10px;
           .remarks-desc {
             font-size: 14px;
             font-weight: 600;
             color: #717272;
           }
           .pce-row {
            margin-bottom: 5px;
            font-weight: 600;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            p {
              &:first-child {
                flex: 0 0 80%;
              }

              &:nth-child(2) {
                font-weight: 400;
                font-size: 14px;
              }
            }
            span.value {
              font-weight: 400;
            }
          }
         }
       }
     }
   }
 }   
}