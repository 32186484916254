.app-dashboard-details-main-container {
    .modal {
        .modal-dialog {
            max-width: 700px;
            .modal-content {
                top: 45px !important;
                border: none;
                .modal-header {
                    background-color: #3e416c;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .modal-title, h5 {
                        font-size: 16px;
                        color: #fff;
                        margin: 0;
                        font-weight: 600;
                    }

                    .close {
                        opacity: 1;
                        padding: 0;
                        margin-right: 10px;
                        .fa.fa-close {
                            color: #fff;
                            font-size: 32px;
                            -webkit-text-stroke: 4px #3e416c;
                        }
                    }
                }

                .modal-body {
                    display: flex;
                    justify-content: flex-start;
                    flex-wrap: wrap;

                    .form-group {
                        width: calc(100% * 1 / 2.001 - 20px);
                        margin: 0 10px;
                        display: flex;
                        flex-wrap: wrap;

                        label {
                            font-size: 14px;
                            color: #3e416c;
                            font-weight: 600;
                        }

                        .choose-file-section {
                            label {
                                background-color: #dddddd;
                                padding: 9px 15px;
                                margin-top: 0;
                                border-radius: 5px;
                                cursor: pointer;
                                margin-bottom: 0;
                                display: inline-block;
                            }
                            input {
                                display: none;
                            }
                        }

                        input, select, textarea {
                            margin-bottom: 5px;
                            border: 1px solid #f1f1f1;
                            height: 2.875pc;
                        }

                        .choose-file-section {
                            label {
                                background-color: #dddddd;
                                padding: 9px 15px;
                                margin-top: 0;
                                border-radius: 5px;
                                cursor: pointer;
                                margin-bottom: 0;
                                display: inline-block;
                            }
                            input {
                                display: none;
                            }
                        }

                        div {
                            padding: 0;
                            &:first-child {
                                flex: 0 0 100%;
                                max-width: unset;

                                label {
                                    font-size: 14px;
                                    color: #3e416c;
                                    font-weight: 600;
                                }
                            }

                            &:nth-child(2) {
                                flex: 0 0 100%;
                                max-width: unset;

                                .choose-file-section {
                                    label {
                                        background-color: #dddddd;
                                        padding: 9px 15px;
                                        margin-top: 0;
                                        border-radius: 5px;
                                        cursor: pointer;
                                        margin-bottom: 0;
                                        display: inline-block;
                                    }
                                    input {
                                        display: none;
                                    }
                                }

                                input, select {
                                    margin-bottom: 0;
                                    border: 1px solid #f1f1f1;
                                    height: 2.875pc;
                                }
                            }
                        }
                    }
                }

                .modal-footer {
                    button {
                        &:first-child {
                            background-color: #3c9be2;
                            border: 1px solid #3c9be2;
                            color: #fff;
                            font-weight: 600;
                        }
                        &:last-child {
                            background-color: #3e416c;
                            border: 1px solid #3e416c;
                            color: #fff;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
        .modal-sm {
            max-width: 400px;
        }
    }
}