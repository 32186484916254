.app-dashboard-details-main-container {

    .no-results-found-container {
        padding: 15px;

        div {
            font-size: 14px;
            font-weight: 600;
        }
    }

    .admin-payments-info-container {
        padding: 15px;

        .grid-slider-header {
            h4 {
                font-size: 16px;
            }
        }

        .grid-slider-header-link {
            a {
                color: #fff;
            }
        }

        .uk-slider-items {
            li {
                display: inline-block;
                width: auto;
                min-width: 150px;
                margin-right: 15px;

                a {
                    border: 1px solid #f1f1f1;
                    font-size: 14px;
                    font-weight: 600;
                    color: #3e416c;
                    background-color: rgba(63, 68, 109, 0.2);
                }

                &:last-child {
                    min-width: 300px;
                }
            }

            li.active-item {
                a {
                    background-color: #3e416c;
                    color: #fff;
                }
            }
        }
    }

    .summary-cards-container {
        padding: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        .summary-card-container {
            margin-bottom: 15px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border-radius: 10px;
            padding: 15px;
            .summary-card-left-side {
                flex: 0 0 75px;
                margin-right: 10px;
                background-color: #ffffff90;
                border-radius: 50%;
                height: 75px;
                display: flex;
                justify-content: center;
                align-items: center;
                .image-container {
                    width: 60%;
                }
            }

            .summary-card-right-side {
                p {
                    font-size: 18px;
                    font-weight: 600;
                    color: #3e416c;
                }
            }
        }
    }

    .acs-payments-history-main-container {
        margin: 0 15px 15px;
        padding-bottom: 25px;
        border-radius: 10px;
    }

    .ac-students-payments-info-container {
        padding: 0 15px 15px;

        .go-back-container {
            display: inline-block;
            color: #3e416c;
            cursor: pointer;
            font-size: 14px;
            font-weight: 600;
        }

        .students-paymet-stats-container {
            margin-bottom: 15px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            p {
                font-size: 14px;
                background-color: #f1f1f1;
                padding: 10px;
                min-width: 120px;
            }
        }
        table {
            width: 100%;
            tr.table-headers-row {
                th {
                    font-size: 14px;
                    color: #fff;
                    background-color: #3e416c;
                    padding: 8px 15px;
                    &:first-child {
                        border-top-left-radius: 10px;
                    }

                    &:last-child {
                        border-top-right-radius: 10px;
                    }

                }
            }

            tr.table-data-row {
                td {
                    font-size: 12px;
                    color: #414142;
                    font-weight: 600;
                }
            }
        }
    }

    .ac-payments-info-left-chart-container {
        .labels-list-container {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            .label-data-container {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-right: 10px;
                margin-bottom: 10px;
                .color-box {
                    width: 15px;
                    height: 15px;
                    border-radius: 15px;
                    margin-right: 5px;
                }
                p {
                    font-size: 9px;
                    font-weight: 600;
                    margin: 0;
                }
            }
        }

        .summary-payment-stats-container {
            ul {
                li {
                    font-size: 12px;
                    color: #414242;
                }
            }
        }
    }

    .ac-student-payments-history-tabs {
        .ac-student-payments-due-section {
            text-align: right;
            margin-right: 15px;
            
            button.btn {
                background-color: #3c9be2;
                color: #fff;
                font-size: 12px;
                padding: 3px 10px;
                margin-top: 15px;
            }

            table {
                text-align: left;
                tr.table-header-row {
                    th {
                        background-color: #3e416c;
                        padding: 8px 15px;
                        text-transform: unset;
                        color: #fff;
                        font-size: 14px;
                        font-weight: 600;

                        &:first-child {
                            border-top-left-radius: 10px;
                        }

                        &:last-child {
                            border-top-right-radius: 10px;
                        }
                    }
                }

                tr.table-data-row {
                    td {
                        border-bottom: 1px solid #f1f1f1;
                        font-size: 12px;
                        color: #414242;
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .expenses-list-table-container {
        padding: 15px;

        table {
            thead {
                tr.table-header-row {
                    th {
                        font-size: 12px;
                        background-color: #3e416c;
                        color: #fff;
                        border: none;
                        padding: 8px 15px;
                        &:first-child {
                            border-top-left-radius: 10px;
                        }

                        &:last-child {
                            text-align: center;
                            border-top-right-radius: 10px;
                        }
                    }
                }
            }

            tr.table-data-row {
                td {
                    .achievement-image,
                    .inventory-image {
                        max-height: 30px;
                    }

                    font-size: 12px;
                    color: #414242;
                    font-weight: 600;
                    &:last-child {
                        .action-items {
                            text-align: center;

                            button {
                                min-width: 25px;
                                font-size: 10px;
                                font-weight: 600;
                                height: 25px;
                                padding: 0;
                                justify-content: center;
                                display: inline-flex;
                                align-items: center;
                                border-radius: 30px;
                                border: none;
                                margin-right: 10px;

                                &:first-child {
                                    background-color: #3e416c;
                                }

                                &:last-child {
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .inventory-track-main-container {
        padding: 15px;

        .inventory-track-top-container {
            .go-back-container {
                font-size: 14px;
                font-weight: 600;
                color: #3e416c;
                cursor: pointer;
            }
        }

        .inventory-details-flex-container {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            margin-top: 15px;
            margin-bottom: 20px;

            .inventory-details-left-side {
                width: 200px;
                margin-right: 15px;
                text-align: center;
                background-color: #f1f1f1;
                border-radius: 10px;
                img {
                    max-height:120px;
                    border-radius: 10px;
                }
            }

            .inventory-details-right-side {
                h4 {
                    font-size: 14px;
                    margin: 0;
                }

                p {
                    font-size: 12px;
                    margin: 0 0 5px;
                    font-weight: 600;
                }

                button {
                    background-color: #3c9be2;
                    padding: 2px 8px;
                    border-radius: 5px;
                    border: 2px solid #3c9be2;
                    color: #fff;
                    font-size: 12px;
                    font-weight: 600;
                    font-family: 'gotham-rounded', 'helvetica neue' ,helvetica;
                    cursor: pointer;
                    outline: none;
                }
            }
        }

        .inventory-track-list-container {
            .list-item {
                padding-left: 0;

                li {
                    display: block;
                }
                h6 {
                    margin: 0 0 5px;
                    font-size: 14px;
                }

                p {
                    font-size: 12px;
                    font-weight: 600;
                    margin: 0 0 5px;
                }
            }
        }
    }
}